import { Grid, makeStyles, Typography, TextField } from "@material-ui/core";
import { ClassicButton } from "./buttons";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  background: {
    borderTop: "1px solid #ebdbd9",
    padding: "150px 0 0 0",
  },
  logo: {
    width: 150,
    marginBottom: 15,
  },
  gridItem: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: 30,
    },
  },
  pages: {
    borderBottom: "1px solid #ebdbd9",
  },
  linkWrapper: {
    width: "70%",
  },
  logoWrapper: {
    // width: "70%",
  },
  links: {
    fontSize: 16,
    margin: "20px 0",
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.down("md")]: {
      margin: "10px auto",
    },
  },
  copyRight: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 0",
    backgroundColor: "#EF6956",
    marginTop: 100,
  },
}));

export default function Footer() {
  const classes = useStyles();
  return (
    <>
      <div className={classes.background}>
        <Grid container style={{ width: "70%", margin: "0 auto" }}>
          <Grid item md={5} xs={12} className={classes.gridItem}>
            {/* <div className={classes.logoWrapper}> */}
            <img src="/logo.png" alt="logo" className={classes.logo} />
            <ClassicButton text="Jetzt Kontaktieren" link="/contact " />
            {/* </div> */}
          </Grid>
          <Grid item md={4} xs={12} className={classes.gridItem}>
            {/* <div className={classes.linkWrapper}> */}
            <div className={classes.pages}>
              <Typography style={{ fontSize: 18, marginBottom: 15 }}>Seiten</Typography>
            </div>
            <Link style={{ color: "black", textDecoration: "none" }} to="/smartLock ">
              <Typography className={classes.links}>KTS Lock</Typography>
            </Link>
            <Link style={{ color: "black", textDecoration: "none" }} to="/smartBoard ">
              <Typography className={classes.links}>KTS Board</Typography>
            </Link>
            <Link style={{ color: "black", textDecoration: "none" }} to="/contact ">
              <Typography className={classes.links}>Kontakt</Typography>
            </Link>
            {/* </div> */}
          </Grid>
          <Grid item xs={12} md={3} className={classes.gridItem}>
            {/* <div className={classes.linkWrapper}> */}
            <div className={classes.pages}>
              <Typography style={{ fontSize: 18, marginBottom: 15 }}>Rechtliches</Typography>
            </div>
            <Link style={{ color: "black", textDecoration: "none" }} to="/agb ">
              <Typography className={classes.links}>AGB</Typography>
            </Link>
            <Link style={{ color: "black", textDecoration: "none" }} to="/impressum ">
              <Typography className={classes.links}>Impressum</Typography>
            </Link>
            <Link style={{ color: "black", textDecoration: "none" }} to="/datenschutz ">
              <Typography className={classes.links}>Datenschutz</Typography>
            </Link>
          </Grid>
        </Grid>
        <div className={classes.copyRight}>
          <Typography style={{ color: "white" }}>© 2021 KTS Key-Tech-System</Typography>
        </div>
      </div>
    </>
  );
}

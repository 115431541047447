import { makeStyles, Typography } from "@material-ui/core";
import Lottie from "react-lottie";
import * as ChargingBattery from "../../lottie/charging-battery.json";

const useStyles = makeStyles((theme) => ({
  background: {
    width: "100%",
    paddingTop: 40,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    fontWeight: "bold",
    fontSize: 55,
    marginTop: 30,
    textAlign: "center",
    lineHeight: 1,
    [theme.breakpoints.down("md")]: {
      fontSize: 38,
    },
  },
  text: {
    width: 880,
    fontSize: 20,
    marginTop: 5,
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
}));

export default function Battery() {
  const classes = useStyles();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require("../../lottie/charging-battery.json"),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <div className={classes.background}>
        <Typography className={classes.header}>Garantierter Zutritt trotz Stromausfall</Typography>
        <Typography className={classes.text}>
          Durch unsere innovative Hardwareentwicklung, sind auch bei einem Stromausfall die Zutritte in das jeweilige Gebäude gewährleistet. Der Strom ist für 48 Stunden gewährleistet und innerhalb
          dieser können über tausend öffnungen durchgeführt werden.
        </Typography>
        <Lottie options={defaultOptions} style={{ width: 300 }} isStopped={false} isPaused={false} />
      </div>
    </>
  );
}

 
import { makeStyles, Typography, Grid, Paper } from "@material-ui/core";
import { useEffect } from "react";
import { ClassicButton } from "../buttons";

const useStyles = makeStyles((theme) => ({
  background: {
    width: "100%",
    paddingTop: 40,
    height: 700,
    marginTop: 150,
    [theme.breakpoints.down("md")]: {
      height: "fit-content",
      marginTop: 30,
    },
  },
  gridContainer: {
    width: "63%",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      width: 800,
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
  },
  gridItemLeft: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      position: "initial",
    },
  },
  image: {
    height: 1000,
    position: "absolute",
    left: -600,
    [theme.breakpoints.down("md")]: {
      position: "initial",
      width: "100%",
      height: "auto",
    },
  },
  header: {
    fontWeight: "bold",
    fontSize: 55,
    marginTop: 40,
    marginBottom: 20,
    [theme.breakpoints.down(642)]: {
      fontSize: 48,
    },
  },
  innerWrapper: {
    width: 600,
    [theme.breakpoints.down(600)]: {
      width: "100%",
    },
  },
}));

export default function Mms() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.background}>
        <Grid container className={classes.gridContainer}>
          <Grid item lg={6} xs={12} className={classes.gridItemLeft}>
            <img src="/smartLock/mockupPC.png" alt="2FA App preview" className={classes.image} />
          </Grid>
          <Grid item lg={6} xs={12} className={classes.gridItem}>
            <div className={classes.innerWrapper}>
              <Typography className={classes.header}>Einfaches Management</Typography>
              <Typography style={{ fontSize: 26, fontWeight: "bold" }}>Sperrungen</Typography>
              <Typography style={{ fontSize: 20, marginTop: 5 }}>
                Durch das zentrale Verwaltungstool sind Sperrungen in sekundenschnelle durchführbar, wenn z.B. eine Zutrittskarte verloren geht.
              </Typography>
              <Typography style={{ marginTop: 30, fontSize: 26, fontWeight: "bold" }}>Freigaben</Typography>
              <Typography style={{ fontSize: 20, marginTop: 5 }}>Kartenberechtigungen sind in Abstimmung mit dem Freigabeberechtigten on-the-fly durchführbar.</Typography>
              <Typography style={{ marginTop: 30, fontSize: 26, fontWeight: "bold" }}>Zeitfenster</Typography>
              <Typography style={{ fontSize: 20, marginTop: 5 }}>
                Je nach Vorgabe des Freigabeberechtigten, können pro Unternehmen Zutrittsberechtigungszeitfenster vergeben werden.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

 
import { makeStyles, Typography, Grid, Paper } from "@material-ui/core";
import { ClassicButton } from "../buttons";

const useStyles = makeStyles((theme) => ({
  imageWrapper: {
    display: "flex",
  },
  imageOne: {
    height: 400,
    [theme.breakpoints.down(700)]: {
      height: 180,
    },
  },
  imageTwo: {
    height: 500,
    position: "relative",
    marginTop: "7%",
    marginLeft: "-450px",
    [theme.breakpoints.down(700)]: {
      height: 180,
      marginLeft: "-156px",
    },
  },
  rightItem: {
    width: "70%",
    margin: "0 auto",
  },
  iconAndTextWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: 40,
  },
  icons: {
    height: 40,
    marginRight: 16,
  },
  gridContainer: {
    width: 1300,
    margin: "250px auto",
    [theme.breakpoints.down("lg")]: {
      width: "90%",
    },
  },
  childToCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function WhyUs() {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.gridContainer}>
        <Grid item lg={6} xs={12} className={classes.childToCenter}>
          <div className={classes.imageWrapper}>
            <img src="/smartBoard/mockup.png" alt="mockup" className={classes.imageOne} />
            <img src="/smartBoard/mockup2.png" alt="mockup" className={classes.imageTwo} />
          </div>
        </Grid>
        <Grid item lg={6} xs={12}>
          <div className={classes.rightItem}>
            <Typography style={{ fontSize: 28, letterSpacing: 2, marginBottom: 25, color: "#EF6956" }}>- Unser Board</Typography>
            <Typography style={{ fontSize: 38, lineHeight: 1, marginBottom: 25, color: "#4c4443" }}>Qualitätsarbeit mit Leidenschaft und Sorgfalt</Typography>
            <Typography style={{ fontSize: 18, marginBottom: 25, color: "#4c4443" }}>
              Inhalte sind jederzeit in Sekunden am KTS Board verfügbar Schneller kann man digitale Kommunikation nicht betreiben
            </Typography>
            <Grid container>
              <Grid item md={6} xs={12}>
                <div className={classes.iconAndTextWrapper}>
                  <img src="/smartBoard/eco-friendly.svg" alt="Umweltfreundlich" className={classes.icons} />
                  <Typography style={{ fontSize: 18, color: "#4c4443" }}>Umweltfreundlich</Typography>
                </div>
              </Grid>
              <Grid item md={6} xs={12}>
                <div className={classes.iconAndTextWrapper}>
                  <img src="/smartBoard/passion.svg" alt="Leidenschaft" className={classes.icons} />
                  <Typography style={{ fontSize: 18, color: "#4c4443" }}>Entwickelt mit Leidenschaft</Typography>
                </div>
              </Grid>
              <Grid item md={6} xs={12}>
                <div className={classes.iconAndTextWrapper}>
                  <img src="/smartBoard/quality.svg" alt="Qualität" className={classes.icons} />
                  <Typography style={{ fontSize: 18, color: "#4c4443" }}>Hohe Qualität</Typography>
                </div>
              </Grid>
              <Grid item md={6} xs={12}>
                <div className={classes.iconAndTextWrapper}>
                  <img src="/smartBoard/maintenance.svg" alt="Instandhaltung" className={classes.icons} />
                  <Typography style={{ fontSize: 18, color: "#4c4443" }}>Einfache Instandhaltung</Typography>
                </div>
              </Grid>
            </Grid>
            <ClassicButton link="/contact " text="Jetzt Bestellen" />
          </div>
        </Grid>
      </Grid>
    </>
  );
}

import { makeStyles, Typography, Grid, TextField, FormControlLabel, withStyles, Checkbox, Tooltip } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { styled } from "@material-ui/styles";
import axios from "axios";
import { useState } from "react";
import Lottie from "react-lottie";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    width: "fit-content",
    padding: "14px 28px",
    backgroundColor: "#EF6956",
    border: "2px solid #EF6956",
    borderRadius: 8,
    color: "white",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "white",
      color: "black",
    },
  },
  textField: {
    width: "80%",
  },
  textFieldMultiRow: {
    width: "90%",
  },
  gridItem: {
    marginTop: 25,
  },
  textWrapper: {
    width: "80%",
    margin: "0 auto",
  },
  infoWrapper: {
    height: 300,
    width: 300,
    borderTopRightRadius: 30,
    borderTopLeftRadius: 30,
    borderBottomLeftRadius: 30,
    border: "1px solid #ebdbd9",
    padding: 37,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  icons: {
    height: 70,
  },
  iconHeader: {
    fontSize: 24,
  },
  iconText: {
    fontSize: 18,
  },
  gridWrapper: {
    width: 1200,
    margin: "150px auto",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  formWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  gridContainerInfos: {
    minWidth: 900,
    maxWidth: 1100,
    margin: "0 auto 250px auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      minWidth: "90%",
      maxWidth: "90%",
    },
  },
  childToCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 50,
    },
  },
}));

const OrangeCheckbox = withStyles({
  root: {
    color: "#EF6956",
    "&$checked": {
      color: "#EF6956",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const OrangeTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "rgb(76, 68, 67)",
    fontSize: 18,
  },
  "& label": {
    color: "rgb(76, 68, 67)",
    fontSize: 18,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#EF6956",
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "#EF6956",
  },
  "& .MuiInputBase-input": {
    marginBottom: 20,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#EF6956",
    },
    "&:hover fieldset": {
      borderColor: "#EF6956",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#EF6956",
    },
  },
});

export default function Contact() {
  const classes = useStyles();

  const [emailSended, setEmailSended] = useState(false);
  const [checkBox, setCheckBox] = useState(false);
  const [captchaChecked, setCaptchaChecked] = useState(false);

  const initialValues = {
    name: "",
    email: "",
    phoneNumber: "",
    subject: "",
    description: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Geben Sie Ihren Namen ein").min(2, "Mehr Zeichen").max(100, "Weniger Zeichen"),
    email: Yup.string().email("Geben Sie eine Email ein").required("Geben sie Ihre email Adresse ein").min(5, "Mehr Zeichen").max(100, "Weniger Zeichen"),
    phoneNumber: Yup.number().positive().typeError("Geben Sie nur Nummern ein").required("Geben Sie eine Telefonnummer ein"),
    subject: Yup.string().required("Geben Sie einen Betreff ein"),
    description: Yup.string().required("Geben Sie eine Nachricht ein"),
  });

  const handleSubmit = async (values) => {
    if (!captchaChecked || !checkBox) return alert("Akzeptieren Sie die Kontaktaufnahme und hacken Sie das Captcha an");

    const data = {
      userName: process.env.REACT_APP_USERNAME,
      password: process.env.REACT_APP_PASSWORD,
      apiKey: process.env.REACT_APP_APIKEY,
      emailTo: process.env.REACT_APP_EMAILTO,
      clientEmail: values.email,
      subject: values.subject,
      message: `<p>Telefonnummer: ${values.phoneNumber}</p>
      <p>Name: ${values.name}</p>
      <p>Nachricht: ${values.description}</p>
      `,
    };
    await axios.post("http://scriptmailer.scriptspace.at/sendEmail", data).then((res) => {
      setEmailSended(!emailSended);
    });
  };

  const mailSended = {
    loop: true,
    autoplay: true,
    animationData: require("./lottie/tick.json"),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <Grid container className={classes.gridWrapper}>
        <Grid item lg={6} xs={12}>
          <div className={classes.textWrapper}>
            <Typography style={{ fontSize: 28, letterSpacing: 2, marginBottom: 25, color: "#EF6956" }}>- Kontakt</Typography>
            <Typography style={{ fontSize: 38, lineHeight: 1.2, marginBottom: 25, color: "#4c4443" }}>Wir werden uns in Kürze bei Ihnen melden!</Typography>
            <Typography style={{ fontSize: 18, marginBottom: 25, color: "#4c4443" }}></Typography>
          </div>
        </Grid>
        <Grid item lg={6} xs={12} className={classes.formWrapper}>
          {!emailSended ? (
            <>
              <Formik validateOnBlur={false} validateOnChange={false} validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                {({ values, errors, touched }) => (
                  <Form style={{ marginLeft: "10%" }} id="frmContact" action="" method="post">
                    <Grid container>
                      <Grid item md={6} xs={12} className={classes.gridItem}>
                        <Field as={OrangeTextField} className={classes.textField} name="name" id="name" size="medium" label="Name" />
                        {errors.name && touched.name ? <div style={{ color: "red" }}>{errors.name}</div> : null}
                      </Grid>
                      <Grid item md={6} xs={12} className={classes.gridItem}>
                        <Field as={OrangeTextField} className={classes.textField} name="email" id="email" size="medium" label="Email" />
                        {errors.email && touched.email ? <div style={{ color: "red" }}>{errors.email}</div> : null}
                      </Grid>
                      <Grid item md={6} xs={12} className={classes.gridItem}>
                        <Field as={OrangeTextField} className={classes.textField} name="phoneNumber" id="phoneNumber" size="medium" label="Telefonnummer" />
                        {errors.phoneNumber && touched.phoneNumber ? <div style={{ color: "red" }}>{errors.phoneNumber}</div> : null}
                      </Grid>
                      <Grid item md={6} xs={12} className={classes.gridItem}>
                        <Field as={OrangeTextField} className={classes.textField} name="subject" id="subject" size="medium" label="Betreff" />
                        {errors.subject && touched.subject ? <div style={{ color: "red" }}>{errors.subject}</div> : null}
                      </Grid>
                      <Grid item xs={12} className={classes.gridItem}>
                        <Field as={OrangeTextField} className={classes.textFieldMultiRow} name="description" id="description" size="medium" label="Beschreibung" multiline rows={4} />
                        {errors.description && touched.description ? <div style={{ color: "red" }}>{errors.description}</div> : null}
                      </Grid>
                      <Grid item xs={12} className={classes.gridItem} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <div style={{ display: "flex", alignItems: "center", marginBottom: 25 }}>
                          <Tooltip title="Bitte anklicken, damit wie Sie per E-Mail oder Telefon kontaktieren dürfen!">
                            <FormControlLabel
                              control={
                                <OrangeCheckbox
                                  checked={checkBox}
                                  onChange={() => {
                                    setCheckBox(!checkBox);
                                  }}
                                  name="checkBox"
                                  color="primary"
                                />
                              }
                              label="Kontaktaufnahme"
                            />
                          </Tooltip>
                          <ReCAPTCHA
                            sitekey="6LcU0GodAAAAABzlfc8HVLH1oaXp_1OOMgmw6m3x"
                            onChange={() => {
                              setCaptchaChecked(!captchaChecked);
                            }}
                          />
                        </div>
                        <button className={classes.buttonWrapper} type="submit" id="sendemail">
                          <Typography style={{ fontSize: 14, width: "fit-content" }}>Jetzt abschicken</Typography>
                        </button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </>
          ) : (
            <Lottie options={mailSended} width={300} height={300} isStopped={false} isPaused={false} />
          )}
        </Grid>
      </Grid>
      <Grid container className={classes.gridContainerInfos}>
        <Grid item md={4} xs={12} className={classes.childToCenter}>
          <div className={classes.infoWrapper}>
            <img src="/location.svg" alt="Location" className={classes.icons} />
            <Typography className={classes.iconHeader}>Besuchen Sie uns</Typography>
            <Typography className={classes.iconText}>Plüddemanngasse 67b</Typography>
            <Typography className={classes.iconText}>8010 Graz</Typography>
          </div>
        </Grid>
        <Grid item md={4} xs={12} className={classes.childToCenter}>
          <div className={classes.infoWrapper}>
            <img src="/contactPhone.svg" alt="contactPhone" className={classes.icons} />
            <Typography className={classes.iconHeader}>Rufen Sie uns an</Typography>
            <Typography className={classes.iconText}>0316-382200-44</Typography>
            {/* <Typography className={classes.iconText}>0316 123 45</Typography> */}
          </div>
        </Grid>
        <Grid item md={4} xs={12} className={classes.childToCenter}>
          <div className={classes.infoWrapper}>
            <img src="/email.svg" alt="Location" className={classes.icons} />
            <Typography className={classes.iconHeader}>Kontaktieren Sie uns</Typography>
            <Typography className={classes.iconText}>office@ktsystems.at</Typography>
            <Typography className={classes.iconText}>anfragen@ktsystems.at</Typography>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

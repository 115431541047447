// import { makeStyles, Typography } from "@material-ui/core";
import Hero from "./components/home/hero";
import Products from "./components/home/products";
import Benefits from "./components/home/benefits";
import Quote from "./components/home/quote";
import OurTeam from "./components/home/ourTeam";
// import ContactUs from "../components/home/contactUs";

// const useStyles = makeStyles((theme) => ({}));

export default function App() {
  return (
    <>
      <Hero />
      <Products />
      <Benefits />
      <Quote />
      <OurTeam />
      {/* <ContactUs /> */}
    </>
  );
}

import * as React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default function Text() {
  return (
    <div style={{ height: "100%", width: "100%", backgroundColor: "lightgray", paddingBottom: 100 }}>
      <Typography style={{ textAlign: "center", paddingTop: 100, marginBottom: 50, fontSize: 48 }}>Datenschutz</Typography>
      <div style={{ width: "90%", margin: "0 auto" }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography>1. Allgemeine Informationen zur Erhebung personenbezogener Daten und Kontaktdaten des Verantwortlichen</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Verantwortlicher gem. Art. 4 Abs. 7 EU-DSGVO für die Verarbeitung personenbezogener Daten: <br />
              <b>KTS Key-Tech-Systems GmbH &amp; Co KG</b>
              <br />
              Erstellung und Vertrieb von Software <br />
              Tel.:0316-382200-44 <br />
              E-Mail: office@ktsystems.at
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
            <Typography>2. Datenverarbeitung von Interessenten, Kunden, Lieferanten und Geschäftspartner</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Wir speichern jene Daten, die uns Interessenten, Kunden, Lieferanten oder Geschäftspartner durch eigene Angaben, etwa im Rahmen einer Anfrage per Email oder zum Abschluss eines Vertrages
              oder einer Geschäftsbeziehung zur Verfügung stellen. Dazu gehören Titel, Geschlecht, Vor- und Nachname, Vor- und Nachnamen von Mitarbeitern, Anschrift und E-Mail-Adresse, Telefonnummer,
              Mobilfunknummer, UID-Nummer, Bankverbindung, Vertragsdaten. Eine Weitergabe an Dritte erfolgt – außer in gesetzlich vorgeschriebenen Fällen – nur nach Ihrer jeweiligen Zustimmung bzw. an
              vertraglich gebundene Auftragsverarbeiter.
              <br />
              <br /> Personenbezogene Daten werden von uns an Dritte nur weitergegeben, wenn dies zum Zwecke der Vertragsabwicklung oder aus gesetzlichen Vorschriften heraus erforderlich ist oder –
              darüber hinaus – nur auf Grundlage einer Einwilligung der betroffenen Person. Mit allen Dritten haben wir – wenn erforderlich – rechtskonforme Auftragsverarbeiterverträge abgeschlossen.
              <br />
              <br />
              Die Daten werden von uns gelöscht, sobald eine Speicherung nicht mehr erforderlich ist, bzw. gesetzliche, wie z. B. umsatzsteuerrechtliche Aufbewahrungspflichten abgelaufen sind. Sollte
              die Grundlage der Verarbeitung eine Einwilligung darstellen, löschen wir die Daten bei Widerruf der Einwilligung – außer es sprechen gesetzliche Vorschriften dagegen. <br />
              Rechtsgrundlage der Datenverarbeitung sind <br /> die Vertragsanbahnung und -erfüllung gem. Art. 6 Abs. 1 lit b DSGVO, um Ihre Anfragen/Aufträge zur vollsten Zufriedenheit abwickeln zu
              können. <br /> gesetzliche Verpflichtungen gem. Art 6 Abs. 1 lit. c DSGVO, die wir erfüllen müssen wie beispielsweise gesetzlich vorgeschriebene Aufbewahrungs- und
              Dokumentationspflichten. <br /> berechtigte Interessen unseres Unternehmens iSv Art 6 Abs 1 lit f DSGVO, <br /> Art 6 Abs. 1 lit a DSGVO bei Einholung von Einwilligungen.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
            <Typography>3. Erhebung personenbezogener Daten beim Besuch unserer Website</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <b>3.1.</b> Kontaktaufnahme Bei Ihrer Kontaktaufnahme mit uns über das Kontaktformular werden die von Ihnen mitgeteilten Daten (Ihre E-Mail-Adresse, Ihr Name, Ihre Telefonnummer) von uns
              gespeichert, um Ihre Fragen zu beantworten. Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr erforderlich ist, oder schränken die Verarbeitung
              ein, falls gesetzliche Aufbewahrungspflichten bestehen. <br />
              <br /> Rechtsgrundlage: Art. 6 Abs. 1 lit a <br />
              <br /> <b>3.2.</b> Informatorische Nutzung der Website <br />
              Bei der bloß informatorischen Nutzung der Website, erheben wir nur die personenbezogenen Daten, die Ihr Browser an unseren Server übermittelt. Wenn Sie unsere Website betrachten möchten,
              erheben wir höchstens jene Daten, die für uns technisch erforderlich sind, um Ihnen unsere Website anzuzeigen und die Stabilität und Sicherheit zu gewährleisten: <br/> <br/> IP-Adresse <br/> Datum und
              Uhrzeit der Anfrage <br/> Zeitzonendifferenz zur Greenwich Mean Time (GMT) <br/> Inhalt der Anforderung (konkrete Seite) <br/> Zugriffsstatus/HTTP-Statuscode <br/> Website, von der die Anforderung kommt
              <br/> Browser <br/> Betriebssystem und dessen Oberfläche <br/> Sprache und Version der Browsersoftware <br/>3.3. Cookies<br/><br/> Zusätzlich zu den zuvor genannten Daten werden bei Ihrer Nutzung unserer Website
              zum Wiedererkennen mehrfacher Nutzung unseres Angebots durch denselben Nutzer/Internetanschlussinhaber Cookies gespeichert. Cookies sind kleine Textdateien, die Ihr Internet-Browser auf
              Ihrem Rechner ablegt und speichert. Cookies können keine Programme ausführen oder Viren auf Ihren Computer übertragen. Sie dienen dazu, das Internetangebot insgesamt nutzerfreundlicher
              und effektiver zu machen.<br/><br/> 3.3.1.<br/><br/> Transiente Cookies Transiente Cookies werden automatisiert gelöscht, wenn Sie den Browser schließen. Dazu zählen insbesondere die Session-Cookies. Diese
              speichern eine sogenannte Session-ID, mit welcher sich verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung zuordnen lassen. Dadurch kann Ihr Rechner wiedererkannt werden, wenn
              Sie auf unsere Website zurückkehren. Die Session-Cookies werden gelöscht, wenn Sie sich ausloggen oder den Browser schließen. <br/><br/>3.3.2. Persistente Cookies <br/><br/>Persistente Cookies werden
              automatisiert nach einer vorgegebenen Dauer gelöscht, die sich je nach Cookie unterscheiden kann. Sie können die Cookies in den Sicherheitseinstellungen Ihres Browsers jederzeit löschen.
              <br/><br/>3.3.3. Browser Einstellungen <br/><br/>Sie können Ihre Browser-Einstellung entsprechend Ihren Wünschen konfigurieren und z. B. die Annahme von Third-Party-Cookies oder allen Cookies ablehnen. Wir
              weisen Sie darauf hin, dass Sie eventuell nicht alle Funktionen dieser Website nutzen können. <br/><br/>Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DS-GVO <br/><br/>3.4. Newsletter<br/><br/> Sie haben die Möglichkeit,
              unseren Newsletter auf unserer Website zu abonnieren. Mit diesem Newsletter informieren wir in regelmäßigen Abständen über Produkte, Neuheiten, Angebote/Aktionen, Gewinnspiele etc. Um
              unseren Newsletter empfangen zu können, benötigen Sie eine gültige E-Mail-Adresse. Mit Ihrer Anmeldung zu unserem Newsletter werden wir Ihre IP-Adresse und das Datum sowie die Uhrzeit
              Ihrer Anmeldung speichern. Dies dient in dem Fall, dass ein Dritter Ihre E- Mail-Adresse missbraucht und ohne Ihr Wissen unseren Newsletter abonniert, als Absicherung unsererseits.
              Weitere Daten werden unsererseits nicht erhoben. Die so erhobenen Daten werden ausschließlich für den Bezug unseres Newsletters verwendet. Eine Weitergabe an Dritte erfolgt nicht. Ein
              Abgleich der so erhobenen Daten mit Daten, die möglicherweise durch andere Komponenten unserer Seite erhoben werden, erfolgt ebenfalls nicht.<br/><br/> Das Abonnement des Newsletters können Sie
              jederzeit stornieren. Nach erfolgter Abmeldung werden wir Ihre Daten nicht weiter für den Newsletter-Versand verwenden. Sollten wir in keinerlei Geschäftsbeziehung zu Ihnen stehen und
              wir dadurch gesetzlichen Aufbewahrungspflichten unterliegen, werden Ihre Daten nach der Abmeldung vom Newsletter gelöscht.<br/><br/> Rechtsgrundlage: Art. 6 Abs. 1 lit. a DSGVO
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
            <Typography>4. Google Dienste</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Wir haben einen Vertrag mit Google Ireland Limited („Google“), eine nach irischem Recht eingetragene und betriebene Gesellschaft (Registernummer: 368047) mit Sitz in Gordon House, Barrow
              Street, Dublin 4, Irland abgeschlossen. Trotzdem kann es vorkommen, dass Daten aus Europa in die USA übermittelt werden, worauf wir als Unternehmen allerdings keinen Einfluss haben.<br/><br/> Für
              die USA besteht aktuell kein Angemessenheitsbeschluss der EU-Kommission im Sinne des Artikels 45 Abs. 1, 3 Datenschutzgrundverordnung (DSGVO). Das heißt, dass die EU-Kommission bislang
              nicht positiv festgestellt hat, dass das landesspezifische Datenschutzniveau dieses Landes dem der Europäischen Union aufgrund der DSGVO entspricht § 4 (geeignete Garantien).<br/><br/> Die DSGVO
              setzt für eine Datenübermittlung in ein Drittland oder an eine internationale Organisation sogenannte geeignete Garantien voraus, Art. 46 Abs. 2, 3 DSGVO. Solche liegen für das o. g.
              Bestimmungsland nicht vor.<br/><br/> Eventuelle Risiken, die sich im Zusammenhang mit den vorgenannten Informationen aktuell für Sie als Betroffenen nicht ausschließen lassen, sind insbesondere: <br/><br/>
              Ihre personenbezogenen Daten könnten möglicherweise über den eigentlichen Zweck der Auftragserfüllung hinaus durch Google USA an andere Dritte (z. B.: US-amerikanische Behörden)
              weitergegeben werden. <br/> Sie können Ihre Auskunftsrechte gegenüber Google USA möglicherweise nicht nachhaltig geltend machen bzw. durchsetzen. <br/> Es besteht möglicherweise eine höhere
              Wahrscheinlichkeit, dass es zu einer nicht korrekten Datenverarbeitung kommen kann, da die technischen organisatorischen Maßnahmen zum Schutz personenbezogener Daten quantitativ und
              qualitativ nicht vollumfänglich den Anforderungen der DSGVO. <br/>Mit Ihrer Zustimmung zur Verarbeitung von (Werbe- und Marketing) Cookies willigen Sie explizit in die Datenübermittlung in die
              USA ein. Sie können diese Einwilligung jederzeit formlos per E-Mail widerrufen. Die Datenverarbeitung, die vor dem Widerruf Ihrer Einwilligung stattgefunden hat, ist von dem Widerruf
              nicht betroffenen und damit rechtskonform.<br/><br/> Rechtsgrundlage für die Nutzung sämtlicher Google Dienste: Art 6 Abs 1 lit a DSGVO<br/><br/>4.1. Google Analytics<br/><br/> Diese Website nutzt die Funktion
              Google Analytcs sowie  “Aktivierung der IP- Anonymisierung” (d.h., dass Google Analytics um den Code „gat._anonymizeIp();“ erweitert wurde, um eine anonymisierte Erfassung von
              IP-Adressen (sog. IP-Masking) zu gewährleisten). Dadurch wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des
              Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt.<br/><br/> Laut
              Angaben von Google wird Google die erlangten Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit
              der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber uns zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht
              mit anderen Daten von Google zusammengeführt. Google wird diese Informationen aber gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben ist oder soweit Dritte diese
              Daten im Auftrag von Google verarbeiten. Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser- Software verhindern; wir weisen Sie jedoch darauf hin,
              dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen der Websites vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten
              und auf Ihre Nutzung der Websites bezogenen Daten (inkl. Ihrer anonymisierten IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem
              folgenden Link (https://tools.google.com/dlpage/gaoptout?hl=de) verfügbare Browser-Plugin herunterladen und installieren. Nähere Informationen zu Nutzungsbedingungen und Datenschutz
              finden Sie unter https://www.google.com/analytics/terms/de  bzw. unter https://support.google.com/analytics/answer/6004245?hl=de <br/><br/>4.2. Einsatz von Google Analytics Conversion Tracking
              (Google Ads)<br/><br/> Diese Webseite nutzt ferner das Google Conversion Tracking. Dabei wird von Google Ads ein Cookie auf Ihrem Rechner gesetzt, sofern Sie über eine Google-Anzeige auf unsere
              Webseite gelangt sind. Diese Cookies verlieren nach 30 Tagen ihre Gültigkeit und dienen nicht der persönlichen Identifizierung. Besucht der Nutzer bestimmte Seiten der Webseite des
              Ads-Kunden und das Cookie ist noch nicht abgelaufen, können Google und der Kunde erkennen, dass der Nutzer auf die Anzeige geklickt hat und zu dieser Seite weitergeleitet wurde. Jeder
              Ads-Kunde erhält ein anderes Cookie. Cookies können somit nicht über die Webseiten von Ads-Kunden nachverfolgt werden. Die mithilfe des Conversion-Cookies eingeholten Informationen
              dienen dazu, Conversion-Statistiken für Ads-Kunden zu erstellen, die sich für Conversion-Tracking entschieden haben. Die Ads-Kunden erfahren die Gesamtanzahl der Nutzer, die auf ihre
              Anzeige geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Sie erhalten jedoch keine Informationen, mit denen sich Nutzer persönlich
              identifizieren lassen. Wenn Sie nicht an dem Tracking- Verfahren teilnehmen möchten, können Sie auch das hierfür erforderliche Setzen eines Cookies ablehnen – etwa per
              Browser-Einstellung, die das automatische Setzen von Cookies generell deaktiviert. Sie können Cookies für Conversion-Tracking auch deaktivieren, indem Sie Ihren Browser so einstellen,
              dass Cookies von der Domain „www.googleadservices.com“ blockiert werden. Googles Datenschutzbelehrung finden Sie hier. Wenn Sie die SSL-Suche, die verschlüsselte Suchfunktion von Google,
              verwenden, werden die Suchbegriffe meistens nicht als Teil der URL in der Verweis-URL gesendet. Hierbei gibt es jedoch einige Ausnahmen, zum Beispiel wenn Sie bestimmte, weniger
              verbreitete Browser verwenden. Weitere Informationen zur SSL- Suche erhalten Sie hier. Suchanfragen oder Informationen in der Verweis-URL könnten unter Umständen auch über Google
              Analytics oder eine Application Programming Interface (API) eingesehen werden. Außerdem erhalten Werbetreibende möglicherweise Informationen zu den genauen Suchbegriffen, durch die ein
              Klick auf eine Anzeige ausgelöst wurde.  https://policies.google.com/faq?hl=de <br/><br/>4.3. Einsatz von Google Maps<br/><br/> Auf dieser Webseite nutzen wir das Angebot von Google Maps. Dadurch können wir
              Ihnen interaktive Karten direkt in der Website anzeigen und ermöglichen Ihnen die komfortable Nutzung der Karten-Funktion. Durch den Besuch auf der Website erhält Google die Information,
              dass Sie die entsprechende Unterseite unserer Website aufgerufen haben. Zudem werden die bereits unter dem Punkt „Informatorische Nutzung der Website“ genannten Daten übermittelt. Dies
              erfolgt unabhängig davon, ob Google ein Nutzerkonto bereitstellt, über das Sie eingeloggt sind, oder ob kein Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind, werden Ihre Daten
              direkt Ihrem Konto zugeordnet. Wenn Sie die Zuordnung mit Ihrem Profil bei Google nicht wünschen, müssen Sie sich vor Aktivierung des Buttons ausloggen. Google speichert Ihre Daten als
              Nutzungsprofile und nutzt sie für Zwecke der Werbung, Marktforschung und/oder bedarfsgerechten Gestaltung seiner Website. Eine solche Auswertung erfolgt insbesondere (selbst für nicht
              eingeloggte Nutzer) zur Erbringung von bedarfsgerechter Werbung und um andere Nutzer des sozialen Netzwerks über Ihre Aktivitäten auf unserer Website zu informieren. Ihnen steht ein
              Widerspruchsrecht zu gegen die Bildung dieser Nutzerprofile, wobei Sie sich zur Ausübung dessen an Google richten müssen. <br/><br/>Weitere Informationen zu Zweck und Umfang der Datenerhebung und
              ihrer Verarbeitung durch den Plug-in-Anbieter erhalten Sie in den Datenschutzerklärungen des Anbieters. Dort erhalten Sie auch weitere Informationen zu Ihren diesbezüglichen Rechten und
              Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre: http://www.google.de/intl/de/policies/privacy.<br/><br/> 4.4. Google Remarketing<br/><br/> Wir setzen auf unserer Website den Dienst Google
              Remarketing ein. Mit Google- Remarketing können Anzeigen für Nutzer geschaltet werden, die unsere Webseite in der Vergangenheit bereits besucht haben. Innerhalb des Google-Werbenetzwerks
              können hierdurch an ihre Interessen angepasste Werbeanzeigen auf unserer Seite dargestellt werden. Google-Remarketing verwendet für diese Auswertung Cookies.<br/><br/> 4.5. Einsatz von Google
              Fonts<br/><br/> Wir verwenden Google Fonts. Die Verwendung von Google Fonts erfolgt ohne Authentisierung und es werden keine Cookies and die Google Fonts API gesendet. Sollten Sie ein Konto bei
              Google haben, werden keine Ihrer Google-Kontodaten an Google während der Nutzung von Google Fonts übermittelt. Google erfasst lediglich die Nutzung von CSS und der verwendeten Fonts und
              speichert diese Daten sicher. Mehr zu diesen und weiteren Fragen finden Sie auf https://developers.google.com/fonts/faq. Welche Daten von Google erfasst werden und wofür diese Daten
              verwendet werden, können Sie auf https://www.google.com/intl/de/policies/privacy/ nachlesen.<br/><br/> 4.6. Einsatz von Google reCaptcha <br/><br/>Wir verwenden den Google-Dienst reCaptcha, um
              festzustellen, ob ein Mensch oder ein Computer eine bestimmte Eingabe in unserem Kontakt- oder Newsletter-Formular macht. Google prüft anhand folgender Daten, ob Sie ein Mensch oder ein
              Computer sind: IP-Adresse des verwendeten Endgeräts, die Webseite, die Sie bei uns besuchen und auf der das Captcha eingebunden ist, das Datum und die Dauer des Besuchs, die
              Erkennungsdaten des verwendeten Browser- und Betriebssystem-Typs, Google- Account, wenn Sie bei Google eingeloggt sind, Mausbewegungen auf den reCaptcha- Flächen sowie Aufgaben, bei
              denen Sie Bilder identifizieren müssen. Rechtsgrundlage für die beschriebene Datenverarbeitung ist Art. 6 Abs. 1 Buchstabe f Datenschutz-Grundverordnung. Es besteht ein berechtigtes
              Interesse auf unserer Seite an dieser Datenverarbeitung, die Sicherheit unserer Webseite zu gewährleisten und uns vor automatisierten Eingaben (Angriffen) zu schützen.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
            <Typography>5. Hotjar</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Wir nutzen Hotjar, ein Analysesoftware der Hotjar Ltd, Level 2, St Julians Business Centre, 3, Elia Zammit Street, St Julians STJ 3155, Malta, Europa. („Hotjar“) (http://www.hotjar.com),
              um die Bedürfnisse unserer Nutzer besser zu verstehen und das Angebot auf dieser Webseite zu optimieren. Mithilfe der Technologie von Hotjar bekommen wir ein besseres Verständnis von den
              Erfahrungen unserer Nutzer (z.B. wieviel Zeit Nutzer auf welchen Seiten verbringen, welche Links sie anklicken, was sie mögen und was nicht etc.) und das hilft uns, unser Angebot am
              Feedback unserer Nutzer auszurichten. Hotjar arbeitet mit Cookies und anderen Technologien, um Informationen über das Verhalten unserer Nutzer und über ihre Endgeräte zu sammeln
              (insbesondere IP Adresse des Geräts (wird nur in anonymisierter Form erfasst und gespeichert), Bildschirmgröße, Gerätetyp (Unique Device Identifiers), Informationen über den verwendeten
              Browser, Standort (nur Land), zum Anzeigen unserer Webseite bevorzugte Sprache). Hotjar speichert diese Informationen in einem pseudonymisierten Nutzerprofil. Die Informationen werden
              weder von Hotjar noch von uns zur Identifizierung einzelner Nutzer verwendet oder mit weiteren Daten über einzelne Nutzer zusammengeführt. Weitere Informationen finden Sie in Hotjars
              Datenschutzerklärung hier. Sie können die Erfassung der Daten durch Hotjar verhindern, indem Sie auf folgenden Link klicken und die dortigen Instruktionen
              befolgen: https://www.hotjar.com/opt-out. https://www.hotjar.com/legal/policies/privacy (der Link zur vorher im Text erwähnten Datenschutzerklärung) Rechtsgrundlage ist Art. 6 Abs. 1
              lit. a DS-GVO
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
            <Typography>6. Server Logfiles</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Zur Optimierung dieser Website in Bezug auf die System-Performance, Benutzerfreundlichkeit und Bereitstellung von nützlichen Informationen über unsere Dienstleistungen erhebt und
              speichert der Provider der Website automatisch Informationen in so genannten Server-Log Files, die Ihr Browser automatisch an uns übermittelt. Davon umfasst sind die Internet-Protokoll
              Adresse (IP-Adresse) des anfragenden Computers (einschließlich mobiler Geräte), Browser und Spracheinstellung, Betriebssystem, Referrer URL, Ihr Internet Service Provider und
              Datum/Uhrzeit. <br/><br/>Eine Zusammenführung dieser Daten mit personenbezogenen Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu prüfen, wenn uns konkrete
              Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden, und die Daten– sollte es einen Hackangriff gegeben haben – an die Strafverfolgungsbehörden weiterzugeben. Eine
              darüberhinausgehende Weitergabe an Dritte erfolgt nicht.<br/><br/> Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DS-GVO
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
            <Typography>7. Facebook</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Unsere Website verwendet Social Plugins („Plugins“) von Facebook (Facebook Ireland Ltd., 4 Grand Canal Square Grand Canal Harbour, Dublin 2, Ireland). Die Plugins sind an einem der
              Facebook Logos erkennbar (dunkelgraues oder schwarzes „f“ auf hellgrauem Kreis oder oder weißes „f“ auf blauer Kachel, oder den Begriffen „Like“, „Gefällt mir“ oder einem „Daumen
              hoch“-Zeichen) oder sind mit dem Zusatz „Facebook Social Plugin“ gekennzeichnet. Die Liste und das Aussehen der Facebook Social Plugins kann hier eingesehen
              werden: https://developers.facebook.com/docs/plugins/t. Wir haben einen Vertrag mit Facebook Irland abgeschlossen, nichtsdestotrotz kann es vorkommen, dass Facebook Irland
              personenbezogene Daten an Facebook USA überträgt. Für die USA besteht aktuell kein Angemessenheitsbeschluss der EU- Kommission im Sinne des Artikels 45 Abs. 1, 3
              Datenschutzgrundverordnung (DSGVO). Das heißt, dass die EU-Kommission bislang nicht positiv festgestellt hat, dass das landesspezifische Datenschutzniveau dieses Landes dem der
              Europäischen Union aufgrund der DSGVO entspricht § 4 (geeignete Garantien). Die DSGVO setzt für eine Datenübermittlung in ein Drittland oder an eine internationale Organisation
              sogenannte geeignete Garantien voraus, Art. 46 Abs. 2, 3 DSGVO. Solche liegen für das o. g. Bestimmungsland nicht vor.<br/><br/> Eventuelle Risiken, die sich im Zusammenhang mit den vorgenannten
              Informationen aktuell für Sie als Betroffenen nicht ausschließen lassen, sind insbesondere: <br/><br/>Ihre personenbezogenen Daten könnten möglicherweise über den eigentlichen Zweck der
              Auftragserfüllung hinaus durch Facebook USA an andere Dritte (z. B.: US-amerikanische Behörden) weitergegeben werden. <br/>Sie können Ihre Auskunftsrechte gegenüber Facebook USA
              möglicherweise nicht nachhaltig geltend machen bzw. durchsetzen. <br/> Es besteht möglicherweise eine höhere Wahrscheinlichkeit, dass es zu einer nicht korrekten Datenverarbeitung kommen
              kann, da die technischen organisatorischen Maßnahmen zum Schutz personenbezogener Daten quantitativ und qualitativ nicht vollumfänglich den Anforderungen der DSGVO <br/><br/>Mit Ihrer Zustimmung
              zur Verarbeitung von (Werbe- und Marketing) Cookies willigen Sie explizit in die Datenübermittlung in die USA ein. Sie können diese Einwilligung jederzeit formlos per E-Mail widerrufen.
              Die Datenverarbeitung, die vor dem Widerruf Ihrer Einwilligung stattgefunden hat, ist von dem Widerruf nicht betroffenen und damit rechtskonform. <br/><br/>Wenn ein Nutzer einer Webseite dieses
              Angebots aufruft, die ein solches Plugin enthält, baut sein Browser eine direkte Verbindung mit den Servern von Facebook auf. Der Inhalt des Plugins wird von Facebook direkt an Ihren
              Browser übermittelt und von diesem in die Webseite eingebunden. Wir haben daher keinen Einfluss auf den Umfang der Daten, die Facebook mit Hilfe dieses Plugins erhebt und informiert die
              Nutzer daher entsprechend seinem Kenntnisstand:<br/><br/> Die so erfassten Daten sind für uns anonym, das heißt wir sehen nicht die personenbezogenen Daten einzelner Nutzer. Diese Daten werden
              jedoch von Facebook gespeichert und verarbeitet, worüber wir Sie entsprechend unserem Kenntnisstand unterrichten. Facebook kann diese Daten mit Ihrem Facebook Konto verbinden und auch
              für eigene Werbezwecke, entsprechend Facebooks- Datenverwendungsrichtlinie verwenden https://www.facebook.com/about/privacy/. Sie können Facebook sowie dessen Partnern das Schalten von
              Werbeanzeigen auf und außerhalb von Facebook ermöglichen. Es kann ferner zu diesen Zwecken ein Cookie auf Ihrem Rechner gespeichert werden.<br/><br/> Diese Einwilligung darf nur von Nutzern, die
              älter als 14 Jahre alt sind erklärt werden. Falls Sie jünger sind, bitten wir, Ihre Erziehungsberechtigten um Rat zu fragen. Bitte klicken Sie hier, wenn Sie Ihre Einwilligung widerrufen
              möchten: https://www.facebook.com/settings?tab=ads. Rechtsgrundlage: Art. 6 Abs. 1 lit. a DSGVO
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
            <Typography>8. Instagram</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Auf unserer Webseite verwenden wir Funktionen des Social Media Netzwerks Instagram der Firma Instagram LLC, 1601 Willow Rd, Menlo Park CA 94025, USA. Mit den Funktionen zum Einbetten von
              Instagram-Inhalten (Embed-Funktion) können wir Bilder und Videos anzeigen. Durch den Aufruf von Seiten, die solche Funktionen nutzen, werden Daten (IP-Adresse, Browserdaten, Datum,
              Uhrzeit, Cookies) an Instagram übermittelt, gespeichert und ausgewertet. Sollten Sie – während Sie auf unserer Webseite surfen – bei Ihrem Instagram-Konto angemeldet sein, werden diese
              Daten Ihrem persönlichen Konto zugeordnet.<br/><br/> Für die USA besteht aktuell kein Angemessenheitsbeschluss der EU-Kommission im Sinne des Artikels 45 Abs. 1, 3 Datenschutzgrundverordnung
              (DSGVO). Das heißt, dass die EU-Kommission bislang nicht positiv festgestellt hat, dass das landesspezifische Datenschutzniveau dieses Landes dem der Europäischen Union aufgrund der
              DSGVO entspricht § 4 (geeignete Garantien).<br/><br/> Die DSGVO setzt für eine Datenübermittlung in ein Drittland oder an eine internationale Organisation sogenannte geeignete Garantien voraus,
              Art. 46 Abs. 2, 3 DSGVO. Solche liegen für das o. g. Bestimmungsland nicht vor. <br/><br/>Eventuelle Risiken, die sich im Zusammenhang mit den vorgenannten Informationen aktuell für Sie als
              Betroffenen nicht ausschließen lassen, sind insbesondere:<br/><br/> Ihre personenbezogenen Daten könnten möglicherweise über den eigentlichen Zweck der Auftragserfüllung hinaus durch Instagram
              USA an andere Dritte (z. B.: US-amerikanische Behörden) weitergegeben werden. <br/> Sie können Ihre Auskunftsrechte gegenüber Instagram USA möglicherweise nicht nachhaltig geltend machen
              bzw. durchsetzen. <br/> Es besteht möglicherweise eine höhere Wahrscheinlichkeit, dass es zu einer nicht korrekten Datenverarbeitung kommen kann, da die technischen organisatorischen
              Maßnahmen zum Schutz personenbezogener Daten quantitativ und qualitativ nicht vollumfänglich den Anforderungen der DSGVO<br/> Mit Ihrer Zustimmung zur Verarbeitung von (Werbe- und Marketing)
              Cookies willigen Sie explizit in die Datenübermittlung in die USA ein. Sie können diese Einwilligung jederzeit formlos per E-Mail widerrufen. Die Datenverarbeitung, die vor dem Widerruf
              Ihrer Einwilligung stattgefunden hat, ist von dem Widerruf nicht betroffenen und damit rechtskonform. <br/><br/>Die Datenschutzrichtlinien, welche Informationen Instagram sammelt und wie sie diese
              verwenden finden Sie auf https://help.instagram.com/519522125107875. Rechtsgrundlage: Art. 6 Abs. 1 lit. a DSGVO
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
            <Typography>9. Pinterest</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Auf unserer Seite verwenden wir Social Plugins des sozialen Netzwerkes Pinterest, das von der Pinterest Inc., 635 High Street, Palo Alto, CA, 94301, USA („Pinterest“) betrieben wird.
              Wenn Sie eine Seite aufrufen, die ein solches Plugin enthält, stellt Ihr Browser eine direkte Verbindung zu den Servern von Pinterest her. Das Plugin übermittelt dabei Protokolldaten an
              den Server von Pinterest in die USA. Diese Protokolldaten enthalten möglicherweise Ihre IP-Adresse, die Adresse der besuchten Websites, die ebenfalls Pinterest-Funktionen enthalten, Art
              und Einstellungen des Browsers, Datum und Zeitpunkt der Anfrage, Ihre Verwendungsweise von Pinterest sowie Cookies.<br/><br/> Weitere Informationen zu Zweck, Umfang und weiterer Verarbeitung und
              Nutzung der Daten durch Pinterest sowie Ihre diesbezüglichen Rechte und Möglichkeiten zum Schutz Ihrer Privatsphäre finden Sie in den den Datenschutzhinweisen von
              Pinterest: https://about.pinterest.com/de/privacy-policy.  Für die USA besteht aktuell kein Angemessenheitsbeschluss der EU-Kommission im Sinne des Artikels 45 Abs. 1, 3
              Datenschutzgrundverordnung (DSGVO). Das heißt, dass die EU-Kommission bislang nicht positiv festgestellt hat, dass das landesspezifische Datenschutzniveau dieses Landes dem der
              Europäischen Union aufgrund der DSGVO entspricht § 4 (geeignete Garantien). <br/><br/>Die DSGVO setzt für eine Datenübermittlung in ein Drittland oder an eine internationale Organisation
              sogenannte geeignete Garantien voraus, Art. 46 Abs. 2, 3 DSGVO. Solche liegen für das o. g. Bestimmungsland nicht vor. <br/><br/>Eventuelle Risiken, die sich im Zusammenhang mit den vorgenannten
              Informationen aktuell für Sie als Betroffenen nicht ausschließen lassen, sind insbesondere: <br/><br/> Ihre personenbezogenen Daten könnten möglicherweise über den eigentlichen Zweck der
              Auftragserfüllung hinaus durch Pinterest an andere Dritte (z. B.: US- amerikanische Behörden) weitergegeben werden. <br/> Sie können Ihre Auskunftsrechte gegenüber Pinterest möglicherweise
              nicht nachhaltig geltend machen bzw. durchsetzen. <br/> Es besteht möglicherweise eine höhere Wahrscheinlichkeit, dass es zu einer nicht korrekten Datenverarbeitung kommen kann, da die
              technischen organisatorischen Maßnahmen zum Schutz personenbezogener Daten quantitativ und qualitativ nicht vollumfänglich den Anforderungen der DSGVO<br/> Mit Ihrer Zustimmung zur
              Verarbeitung von (Werbe- und Marketing) Cookies willigen Sie explizit in die Datenübermittlung in die USA ein. Sie können diese Einwilligung jederzeit formlos per E-Mail widerrufen. Die
              Datenverarbeitung, die vor dem Widerruf Ihrer Einwilligung stattgefunden hat, ist von dem Widerruf nicht betroffenen und damit rechtskonform.<br/><br/> Rechtsgrundlage: Art. 6 Abs. 1 lit. a DSGVO
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
            <Typography>10. YouTube</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Wenn Sie eines unserer YouTube-Videos ansehen, wird eine Verbindung zu Servern von YouTube aufgebaut. Dabei wird Youtube mitgeteilt, welche Seiten Sie besuchen. Betreibergesellschaft von
              YouTube ist die YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA. Die YouTube, LLC ist einer Tochtergesellschaft der Google Inc., 1600 Amphitheatre Pkwy, Mountain View, CA
              94043-1351, USA.<br/><br/> Weitere Informationen zum Datenschutz bei „Youtube“ finden Sie in der Datenschutzerklärung des Anbieters unter: https://www.google.de/intl/de/policies/privacy/ Für die
              USA besteht aktuell kein Angemessenheitsbeschluss der EU-Kommission im Sinne des Artikels 45 Abs. 1, 3 Datenschutzgrundverordnung (DSGVO). Das heißt, dass die EU-Kommission bislang nicht
              positiv festgestellt hat, dass das landesspezifische Datenschutzniveau dieses Landes dem der Europäischen Union aufgrund der DSGVO entspricht § 4 (geeignete Garantien). <br/><br/>Die DSGVO setzt
              für eine Datenübermittlung in ein Drittland oder an eine internationale Organisation sogenannte geeignete Garantien voraus, Art. 46 Abs. 2, 3 DSGVO. Solche liegen für das o. g.
              Bestimmungsland nicht vor. Eventuelle Risiken, die sich im Zusammenhang mit den vorgenannten Informationen aktuell für Sie als Betroffenen nicht ausschließen lassen, sind insbesondere: <br/><br/>
              Ihre personenbezogenen Daten könnten möglicherweise über den eigentlichen Zweck der Auftragserfüllung hinaus durch YouTube an andere Dritte (z. B.: US- amerikanische Behörden)
              weitergegeben werden. <br/> Sie können Ihre Auskunftsrechte gegenüber YouTube möglicherweise nicht nachhaltig geltend machen bzw. durchsetzen. <br/> Es besteht möglicherweise eine höhere
              Wahrscheinlichkeit, dass es zu einer nicht korrekten Datenverarbeitung kommen kann, da die technischen organisatorischen Maßnahmen zum Schutz personenbezogener Daten quantitativ und
              qualitativ nicht vollumfänglich den Anforderungen der DSGVO <br/>Mit Ihrer Zustimmung zur Verarbeitung von (Werbe- und Marketing) Cookies willigen Sie explizit in die Datenübermittlung in die
              USA ein. Sie können diese Einwilligung jederzeit formlos per E-Mail widerrufen. Die Datenverarbeitung, die vor dem Widerruf Ihrer Einwilligung stattgefunden hat, ist von dem Widerruf
              nicht betroffenen und damit rechtskonform.<br/><br/> Rechtsgrundlage: Art 6 Abs 1 lit a DSGVO
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
            <Typography>11. Vimeo</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Auf unserer Website verwenden wir Plugins des Anbieters Vimeo. Vimeo wird betrieben von Vimeo, LLC mit Hauptsitz in 555 West 18th Street, New York, New York 10011. Wenn Sie die mit einem
              solchen Plugin versehene Internetseite aufrufen, wird eine Verbindung zu den Vimeo-Servern hergestellt und dabei das Plugin dargestellt. Hierdurch wird an den Vimeo-Server übermittelt,
              welche Internetseite Sie besucht haben. Sind Sie dabei als Mitglied bei Vimeo eingeloggt, ordnet Vimeo diese Information Ihrem persönlichen Benutzerkonto zu. Bei Nutzung des Plugins wie
              z.B. Anklicken des Start-Buttons eines Videos wird diese Information ebenfalls Ihrem Benutzerkonto zugeordnet. Sie können diese Zuordnung verhindern, indem Sie sich vor der Nutzung
              unserer Internetseite aus ihrem Vimeo-Benutzerkonto abmelden und die entsprechenden Cookies von Vimeo löschen.<br/><br/> Weitere Informationen zur Datenverarbeitung und Hinweise zum Datenschutz
              durch Vimeo finden Sie unter https://vimeo.com/privacy. Für die USA besteht aktuell kein Angemessenheitsbeschluss der EU-Kommission im Sinne des Artikels 45 Abs. 1, 3
              Datenschutzgrundverordnung (DSGVO). Das heißt, dass die EU-Kommission bislang nicht positiv festgestellt hat, dass das landesspezifische Datenschutzniveau dieses Landes dem der
              Europäischen Union aufgrund der DSGVO entspricht § 4 (geeignete Garantien).<br/><br/> Die DSGVO setzt für eine Datenübermittlung in ein Drittland oder an eine internationale Organisation
              sogenannte geeignete Garantien voraus, Art. 46 Abs. 2, 3 DSGVO. Solche liegen für das o. g. Bestimmungsland nicht vor. <br/><br/>Eventuelle Risiken, die sich im Zusammenhang mit den vorgenannten
              Informationen aktuell für Sie als Betroffenen nicht ausschließen lassen, sind insbesondere: <br/><br/> Ihre personenbezogenen Daten könnten möglicherweise über den eigentlichen Zweck der
              Auftragserfüllung hinaus durch Vimeo an andere Dritte (z. B.: US- amerikanische Behörden) weitergegeben werden. <br/> Sie können Ihre Auskunftsrechte gegenüber Vimeo möglicherweise nicht
              nachhaltig geltend machen bzw. durchsetzen. <br/> Es besteht möglicherweise eine höhere Wahrscheinlichkeit, dass es zu einer nicht korrekten Datenverarbeitung kommen kann, da die technischen
              organisatorischen Maßnahmen zum Schutz personenbezogener Daten quantitativ und qualitativ nicht vollumfänglich den Anforderungen der DSGVO <br/><br/>  Mit Ihrer Zustimmung zur Verarbeitung von
              (Werbe- und Marketing) Cookies willigen Sie explizit in die Datenübermittlung in die USA ein. Sie können diese Einwilligung jederzeit formlos per E-Mail widerrufen. Die
              Datenverarbeitung, die vor dem Widerruf Ihrer Einwilligung stattgefunden hat, ist von dem Widerruf nicht betroffen und damit rechtskonform. <br/><br/>Rechtsgrundlage: Art. 6 Abs. 1 lit. a DSGVO
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
            <Typography>12. Twitter</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.Auf unserer Seite verwenden wir Social Plugins des sozialen
              Netzwerkes Twitter, Inc.,1355 Market Street, Suite 900, San Francisco, CA 94103, USA. Wenn Sie eine Seite aufrufen, die ein solches Plugin enthält, stellt Ihr Browser eine direkte
              Verbindung zu den Servern von Twitter her. Das Plugin übermittelt dabei Protokolldaten an den Server von Twitter in die USA. Diese Protokolldaten enthalten möglicherweise Ihre
              IP-Adresse, die Adresse der besuchten Websites, die ebenfalls Twitter-Funktionen enthalten, Art und Einstellungen des Browsers, Datum und Zeitpunkt der Anfrage, Ihre Verwendungsweise von
              Twitter sowie Cookies.<br/><br/> Weitere Informationen zu Zweck, Umfang und weiterer Verarbeitung und Nutzung der Daten durch Twitter sowie Ihre diesbezüglichen Rechte und Möglichkeiten zum Schutz
              Ihrer Privatsphäre finden Sie in den Datenschutzhinweisen von Twitter: https://twitter.com/de/privacy Für die USA besteht aktuell kein Angemessenheitsbeschluss der EU-Kommission im Sinne
              des Artikels 45 Abs. 1, 3 Datenschutzgrundverordnung (DSGVO). Das heißt, dass die EU-Kommission bislang nicht positiv festgestellt hat, dass das landesspezifische Datenschutzniveau
              dieses Landes dem der Europäischen Union aufgrund der DSGVO entspricht § 4 (geeignete Garantien).<br/><br/> Die DSGVO setzt für eine Datenübermittlung in ein Drittland oder an eine internationale
              Organisation sogenannte geeignete Garantien voraus, Art. 46 Abs. 2, 3 DSGVO. Solche liegen für das o. g. Bestimmungsland nicht vor.<br/><br/> Eventuelle Risiken, die sich im Zusammenhang mit den
              vorgenannten Informationen aktuell für Sie als Betroffenen nicht ausschließen lassen, sind insbesondere: <br/><br/> Ihre personenbezogenen Daten könnten möglicherweise über den eigentlichen Zweck
              der Auftragserfüllung hinaus durch Twitter an andere Dritte (z. B.: US- amerikanische Behörden) weitergegeben werden. <br/> Sie können Ihre Auskunftsrechte gegenüber Twitter möglicherweise
              nicht nachhaltig geltend machen bzw. durchsetzen. <br/> Es besteht möglicherweise eine höhere Wahrscheinlichkeit, dass es zu einer nicht korrekten Datenverarbeitung kommen kann, da die
              technischen organisatorischen Maßnahmen zum Schutz personenbezogener Daten quantitativ und qualitativ nicht vollumfänglich den Anforderungen der DSGVO <br/><br/>Mit Ihrer Zustimmung zur
              Verarbeitung von (Werbe- und Marketing) Cookies willigen Sie explizit in die Datenübermittlung in die USA ein. Sie können diese Einwilligung jederzeit formlos per E-Mail widerrufen. Die
              Datenverarbeitung, die vor dem Widerruf Ihrer Einwilligung stattgefunden hat, ist von dem Widerruf nicht betroffenen und damit rechtskonform. <br/><br/>Rechtsgrundlage: Art. 6 Abs. 1 lit. a DSGVO
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
            <Typography>13. Cloud Flare</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Unsere Website verwendet ein sogenanntes &quot;Content Delivery Network&quot; (CDN) der Firma Cloudflare, Inc., 101 Townsend Street, San Francisco, CA 94107, USA. <br/><br/>Mit dem CDN-Service
              können Inhalte aus unserem Online-Angebot schneller bereitgestellt werden.<br/><br/> Für den Datenaustausch mit in den USA ansässigen Unternehmen gibt es derzeit keine Angemessenheitsentscheidung
              der EU-Kommission im Sinne von Artikel 45 Absatz 1 Nummer 3 der Allgemeinen Datenschutzverordnung (DSGVO). Dies bedeutet, dass die EU-Kommission bisher nicht eindeutig festgestellt hat,
              dass das länderspezifische Datenschutzniveau dieses Landes dem der Europäischen Union gemäß der DSGVO entspricht (Abschnitt 4 - angemessene Garantien). <br/><br/>Die DSGVO setzt sogenannte
              angemessene Garantien für die Übermittlung von Daten an ein Drittland oder an eine internationale Organisation voraus, Artikel 46 Absatz 2 Nummer 3 DSGVO. Dies ist für das oben genannte
              Bestimmungsland nicht verfügbar. <br/><br/> Alle Risiken, die für Sie als betroffene Person im Zusammenhang mit den oben genannten Informationen nicht ausgeschlossen werden können, sind
              insbesondere: <br/> Ihre persönlichen Daten können zusätzlich zum eigentlichen Zweck der Erfüllung der Bestellung durch Cloudflare Inc. an andere Dritte (z. B. US- Behörden) weitergegeben
              werden. <br/> Möglicherweise können Sie Ihr Zugriffsrecht auf Cloudflare Inc. nicht nachhaltig durchsetzen. <br/> Es besteht möglicherweise eine höhere Wahrscheinlichkeit, dass eine fehlerhafte
              Datenverarbeitung auftritt, da die technischen und organisatorischen Maßnahmen zum Schutz personenbezogener Daten die Anforderungen der DSGVO sowohl quantitativ als auch qualitativ nicht
              vollständig erfüllen.<br/> Indem Sie der Verarbeitung von (Werbe- und Marketing-) Cookies zustimmen, stimmen Sie der Übertragung von Daten in die USA ausdrücklich zu. Sie können diese
              Einwilligung informell widerrufen, indem Sie uns jederzeit eine E-Mail senden. Die Datenverarbeitung, die vor dem Widerruf Ihrer Einwilligung stattgefunden hat, ist vom Widerruf nicht
              betroffen und daher rechtlich konform.<br/><br/> Weitere Informationen finden Sie in den Datenschutzbestimmungen von Cloudflare: https://www.cloudflare.com/security-policy. Rechtsgrundlage: Art. 6
              Abs. 1 lit. a DSGVO
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
            <Typography>14. ClickCease Schutz vor Klickbetrug</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Diese Website nutzt die Funktionen des Werbeanalysedienstes Clickcease (CHEQ AI Technologies Ltd., Yehuda Halevi 23 St. Tel Aviv, Israel). Der Anbieter erhebt, speichert und verarbeitet
              Informationen, die Ihr Browser automatisch übermittelt. Das sind insbesondere der Browsertyp und die Browserversion, das verwendete Betriebssystem, die Referrer-URL, der Hostname des
              zugreifenden Rechners, die Uhrzeit der Serveranfrage und die IP-Adresse. Außerdem wird Ihre Aufenthaltsdauer auf unseren Seiten, die Anzahl der Seitenaufrufe sowie genutzte Suchwörter
              gespeichert.<br/> Der Anbieter des Webtools analysiert diese Daten nach auffälligem Verhalten in Bezug auf Klickbetrug, also die Häufigkeit von Werbeaufrufen einer IP. Die erhobenen Daten
              werden außerhalb des Europäischen Wirtschaftsraumes verarbeitet und gespeichert. Der Sitz des Unternehmens ist in Israel, das Land unterliegt datenschutzrechtlich einem
              Angemessenheitsbeschluss der Europäischen Union. Nähere Informationen und die Datenschutzbestimmungen können unter der folgenden Adresse abgerufen
              werden: https://www.clickcease.com/privacy .<br/><br/> Rechtsgrundlage: Art. 6 Abs. 1 S. 1 lit. a DSGVO.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
            <Typography>15. Veröffentlichung von Stellenanzeigen / Online- Stellenbewerbungen</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Ihre Bewerbungsdaten werden zum Zwecke der Abwicklung des Bewerbungsverfahrens elektronisch von uns erhoben und verarbeitet. Folgt auf Ihre Bewerbung der Abschluss eines
              Anstellungsvertrages, so werden Ihre übermittelten Daten zum Zwecke des üblichen Organisations- und Verwaltungsprozesses unter Beachtung der einschlägigen rechtlichen Vorschriften von
              uns in Ihrer Personalakte gespeichert.<br/><br/> Die Löschung der von Ihnen übermittelten Daten erfolgt bei Zurückweisung Ihrer Stellenbewerbung automatisch zwei Monate nach Bekanntgabe der
              Zurückweisung. Dies gilt nicht, wenn aufgrund gesetzlicher Erfordernisse (beispielsweise der Beweispflicht nach dem Allgemeinen Gleichbehandlungsgesetz) eine längere Speicherung
              notwendig ist oder wenn Sie einer längeren Speicherung in unserer Interessenten-Datenbank ausdrücklich zugestimmt haben.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
            <Typography>16. Ihre Rechte</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten: <br/><br/> Recht auf Auskunft, Berichtigung und Löschung <br/> Recht auf Einschränkung der
              Verarbeitung <br/> Recht auf Widerspruch gegen die Verarbeitung <br/> Recht auf Datenübertragbarkeit<br/> Richten Sie Ihre Anfragen und Anliegen bitte per E-Mail an office@ktsystems.at oder
              kontaktieren Sie uns bitte unter den angegebenen Kontaktdaten. Sollten Ihre von uns verarbeiteten Daten nicht richtig sein, unterrichten Sie uns bitte darüber. Wir werden diese
              unverzüglich richtigstellen und Sie darüber informieren. Im Falle, dass Sie eine Verarbeitung Ihrer Daten durch uns nicht länger wünschen, ersuchen wir Sie, uns dies formlos mitzuteilen.
              Wir werden Ihre Daten selbstverständlich umgehend löschen und Sie davon informieren. Sollten zwingende rechtliche Gründe einer Löschung entgegenstehen, werden Sie dahingehend umgehend
              von uns benachrichtigt.<br/><br/> Sollten Sie der Auffassung sein, dass wir bei der Verarbeitung Ihrer Daten gegen österreichisches oder europäisches Datenschutzrecht verstoßen und dadurch Ihre
              Rechte verletzt haben, ersuchen wir Sie mit uns in Kontakt zu treten, um allfällige Fragen aufklären zu können.<br/><br/> Sie haben zudem das Recht, sich bei der Aufsichtsbehörde, das ist die
              österreichische Datenschutzbehörde, zu beschweren:<br/><br/> <b> Österreichische Datenschutzbehörde </b> <br/> Barichgasse 40 - 42<br/><br/> 1030 Wien<br/><br/> Telefon: +43 1 52 152-0<br/><br/> E-Mail: dsb@dsb.gv.at
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
            <Typography>17. Änderungen dieser Datenschutzerklärung</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Wir behalten uns vor, von Zeit zu Zeit Anpassungen an unserer Datenschutzerklärung vorzunehmen. Wir werden Ihre Rechte nach dieser Datenschutzerklärung nicht ohne Ihre ausdrückliche
              Einwilligung einschränken. Alle Änderungen der Datenschutzerklärung werden von uns auf dieser Seite veröffentlicht werden. Bitte beachten Sie diesbezüglich die jeweils aktuelle Version
              unserer Datenschutzerklärung.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}

 
 
import { Grid, makeStyles, Typography, TextField } from "@material-ui/core";
import { useState, useEffect } from "react";
import { ClassicButton } from "../buttons";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    width: 1536,
    margin: "0 auto",
    [theme.breakpoints.down("lg")]: {
      width: "95%",
    },
  },
  gridItem: {
    width: "100%",
    padding: 30,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  textWrapper: {
    width: 500,
    height: "fit-content",
    [theme.breakpoints.down("md")]: {
      width: "95%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "99%",
    },
  },
  image: {
    width: 500,
    [theme.breakpoints.down("md")]: {
      width: 400,
    },
    [theme.breakpoints.down("xs")]: {
      width: "95%",
      maxWidth: 400,
    },
  },
}));

export default function Products() {
  const classes = useStyles();
  return (
    <>
      <div>
        <Typography style={{ textAlign: "center", fontSize: 42, fontWeight: 300, margin: "100px auto 40px auto" }}>Entdecken Sie unsere Produkte</Typography>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={12} md={6} className={classes.gridItem}>
            <div className={classes.textWrapper}>
              <Typography style={{ fontSize: 26, fontWeight: "bold" }}>KTS Lock</Typography>
              <Typography style={{ fontSize: 18, marginBottom: 15 }}>
                Mit unseren innovativen Zutrittssystemen für Mehrparteienhäuser können Sie jeden Hauseingang modernisieren. Auf Anfrage bieten wir auch individuelle Lösungen für Privathaushalte an.{" "}
              </Typography>
              <div style={{ display: "flex", alignItems: "center" }}>
                <ClassicButton link="/smartLock " text="Mehr Erfahren" />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} className={classes.gridItem}>
            <img src="/smartLock/keycard-scan-system.svg" className={classes.image} />
          </Grid>
        </Grid>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={12} md={6} className={classes.gridItem}>
            <img src="/smartBoard/mockup.png" className={classes.image} />
          </Grid>
          <Grid item xs={12} md={6} className={classes.gridItem}>
            <div className={classes.textWrapper}>
              <Typography style={{ fontSize: 26, fontWeight: "bold" }}>KTS Board</Typography>
              <div>
                <Typography style={{ fontSize: 18, marginBottom: 15 }}>
                  Das zukunftsweisende KTS Board digitalisiert und ersetzt den Aushang im Stiegenhaus. Dadurch werden Papier, Ressourcen und unerwünschte Werbung reduziert.
                </Typography>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ClassicButton link="/smartBoard " text="Mehr Erfahren" />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

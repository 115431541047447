import { Button, Grid, makeStyles, Paper, Switch, Typography, withStyles } from "@material-ui/core";
import { useState, useEffect } from "react";
import cookie from "react-cookies";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  darkBackground: {
    height: "100vh",
    width: "100vw",
    position: "fixed",
    zIndex: 1,
    top: 0,
    right: 0,
    backgroundColor: "#00000073",
  },
  wrapper: {
    height: 500,
    width: "90%",
    zIndex: 999,
    position: "fixed",
    transform: "translate(-50%,-50%)",
    top: "50%",
    left: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 14,
    scrollBehavior: "smooth",
    overflowY: "auto",
    overflowX: "hidden",
    [theme.breakpoints.up("sm")]: {
      width: 500,
    },
  },
  buttonWrapper: {
    position: "sticky",
    bottom: "-14px",
    width: "120%",
    backgroundColor: "white",
    paddingBottom: 14,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    borderRadius: 8,
    backgroundColor: "#EF6956",
    width: "80%",
    height: 50,
    marginTop: 15,
    border: "2px solid transparent",
    "&:hover": {
      border: "2px solid #EF6956",
      color: "black",
      backgroundColor: "white",
    },
  },
  divider: {
    marginTop: 15,
    width: "100%",
    height: "auto",
    borderTop: "1px solid lightgray",
    marginBottom: 14,
  },
  centerGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    fontWeight: "bold",
    fontSize: 24,
  },
  subHeader: {
    fontSize: "13pt",
  },
}));

export default function Cookies(props) {
  const classes = useStyles();
  const [renderHelmetState, setRenderHelmetState] = useState(false);
  const [switchState, setSwitchState] = useState({
    functional: false,
    performance: false,
  });

  const handleStateChange = (event) => {
    setSwitchState({
      ...switchState,
      [event.target.name]: event.target.checked,
    });
  };

  const [showBackground, setShowBackground] = useState(false);

  const checkSettings = () => {
    setRenderHelmetState(switchState.performance);
    setCookiesAccepted();
    document.getElementById("cookieWrapper").style.display = "none";
  };

  const acceptAll = () => {
    setSwitchState({
      performance: true,
      functional: true,
    });
    setRenderHelmetState(true);
    setCookiesAccepted();
    document.getElementById("cookieWrapper").style.display = "none";
  };

  const setCookiesAccepted = () => {
    const expires = new Date();
    expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 30);

    cookie.save("cookieSaved", true, {
      path: "/",
      expires,
    });
  };

  const cookieAlreadyExists = cookie.load("cookieSaved");

  const renderHelmet = () => {
    if (renderHelmetState) {
      return (
        <></>
        // <Helmet>
        //   <script async src="https://www.googletagmanager.com/gtag/js?id=UA-177945081-1"></script>
        //   <script>
        //     {`window.dataLayer = window.dataLayer || [];
        //       function gtag(){dataLayer.push(arguments);}
        //       gtag('js', new Date());

        //       gtag('config', 'UA-177945081-1');`}
        //   </script>
        //   <script async src="https://www.googletagmanager.com/gtag/js?id=UA-25718295-3"></script>
        //   <script>
        //     {`window.dataLayer = window.dataLayer || [];
        //       function gtag(){dataLayer.push(arguments);}
        //       gtag('js', new Date());

        //       gtag('config', 'UA-25718295-3');`}
        //   </script>
        //   <script async src="https://www.googletagmanager.com/gtag/js?id=AW-987810796"></script>
        //   <script>
        //     {`window.dataLayer = window.dataLayer || [];
        //       function gtag(){dataLayer.push(arguments);}
        //       gtag('js', new Date());

        //       gtag('config', 'AW-987810796');`}
        //   </script>
        //   <script>
        //     {`function gtag_report_conversion(url) {
        //         var callback = function () {
        //           if (typeof(url) != 'undefined') {
        //             window.location = url;
        //           }
        //         };
        //         gtag('event', 'conversion', {
        //             'send_to': 'AW-987810796/8gPaCL_QyYsCEOyXg9cD',
        //             'event_callback': callback
        //         });
        //         return false;
        //       }`}
        //   </script>
        // </Helmet>
      );
    }
  };

  const OrangeSwitch = withStyles({
    switchBase: {
      color: "#EF6956",
      "&$checked": {
        color: "#EF6956",
      },
      "&$checked + $track": {
        backgroundColor: "#EF6956",
      },
    },
    checked: {},
    track: {},
  })(Switch);

  useEffect(() => {
    if (cookieAlreadyExists) {
      document.getElementById("cookieWrapper").style.display = "none";
    } else {
      setShowBackground(true);
    }
  });

  return (
    <>
      <div className={classes.darkBackground} style={{ display: showBackground ? "block" : "none" }}></div>
      <Paper className={classes.wrapper} id="cookieWrapper">
        <Grid container style={{ marginBottom: 14 }}>
          <Grid item xs={12} className={classes.centerGrid}>
            <img src="/cookie.svg" style={{ height: 100, marginBottom: 20 }} alt="cookieLogo" />
          </Grid>
          <Grid item xs={12} className={classes.centerGrid}>
            <Typography className={classes.header}>Cookie Einstellungen</Typography>
          </Grid>
        </Grid>
        <Typography>
          Wenn Sie auf "Alle akzeptieren" klicken, stimmen Sie zu, dass Key-Tech-Systems Cookies auf Ihrem Gerät speichert und Informationen gemäß unseren Cookie-Richtlinie offenlegt.
        </Typography>
        <Typography>
          Die Key-Tech-Systems Webseite verwendet Dienste (u.a. von Drittanbietern) um unseren Internetauftritt fortlaufend zu verbessern (Optimierung der Webseite) und Inhalte zu erstellen, die auf
          Ihren Interessen angepasst sind (Marketing). Die unten Angeführten Kategorien von Cookies werden eingesetzt und können von Ihnen durch die Cookie Einstellungen verwaltet werden. Um die
          angeführten Cookie Dienste verwenden zu können brauchen wir Ihre Einwilligung. Weitere Informationen finden Sie in den Datenschutzbestimmungen.
        </Typography>
        <div className={classes.divider}></div>
        <div>
          <Typography className={classes.subHeader}>Unverzichtbare Cookies</Typography>
          <Typography>
            Wir brauchen unverzichtbare Cookies um grundlegende Funktionen der Webseite zur Verfügung zu stellen und die Erfahrung mit der Key Tech Systems Webseite einzigartig zu machen.
          </Typography>
        </div>
        <div className={classes.divider}></div>
        <Grid container>
          <Grid item xs={10}>
            <Typography className={classes.subHeader}>Funktionale Cookies</Typography>
            <Typography>Funktionale Cookies werden eingesetzt, um Benutzereinstellungen zu erfassen und zu speichern. Dies Erhöht die Benutzerfreundlichkeit.</Typography>
          </Grid>
          <Grid item xs={2}>
            <OrangeSwitch checked={switchState.functional} name="functional" onChange={handleStateChange} />
          </Grid>
        </Grid>
        <div className={classes.divider}></div>
        <Grid container>
          <Grid item xs={10} style={{ marginBottom: 25 }}>
            <Typography className={classes.subHeader}>Performance Cookies</Typography>
            <Typography>Mit Performance Cookies sammeln wir Informationen über die Nutzung der Webseite wie zum Beispiel Anzahl Besucher, Verweilzeit usw.</Typography>
          </Grid>
          <Grid item xs={2}>
            <OrangeSwitch checked={switchState.performance} name="performance" onChange={handleStateChange} />
          </Grid>
        </Grid>
        <div className={classes.buttonWrapper}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => {
              acceptAll();
              setShowBackground(false);
            }}
          >
            Alle Akzeptieren
          </Button>
          <Button
            className={classes.button}
            variant="outlined"
            style={{ backgroundColor: "transparent" }}
            onClick={() => {
              checkSettings();
              setShowBackground(false);
            }}
            type="reset"
          >
            Einstellungen speichern
          </Button>
        </div>
      </Paper>
      {renderHelmet()}
    </>
  );
}

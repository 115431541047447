import { makeStyles, Typography, Grid, useTheme, useMediaQuery, CssBaseline, Button, AppBar, Drawer, Toolbar, Divider, IconButton } from "@material-ui/core";
import { useEffect } from "react";
import { ClassicButton } from "./buttons";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import clsx from "clsx";
import { useState } from "react";
import { Link } from "react-router-dom";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  outerWrapper: {
    width: "100%",
    height: 116,
    borderBottom: "1px solid #ebdbd9",
    display: "flex",
    alignItems: "center",
  },
  innerWrapper: {
    width: 1120,
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  leftItem: {
    display: "flex",
    alignItems: "center",
  },
  link: {
    marginRight: 24,
    fontSize: 18,
    color: "rgb(76, 68, 67)",
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: 10,
    },
  },
  rightItem: {
    display: "flex",
    justifyContent: "end",
  },
  logo: {
    height: 70,
    marginRight: 40,
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: 10,
    },
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  title: {
    textAlign: "center",
    flexGrow: 1,
    alignSelf: "center",
    marginLeft: 25,
  },
  appBar: {
    backgroundColor: "transparent",
    color: "white",
    boxShadow: "none",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  menuButton: {
    backgroundColor: "white",
    color: "black",
    marginRight: theme.spacing(2),
    border: "1px solid black",
  },
  hide: {
    display: "none",
  },
}));

export default function NavBar() {
  const classes = useStyles();

  const theme = useTheme();
  const size = useMediaQuery(theme.breakpoints.up("sm"));
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  if (size) {
    return (
      <>
        <div className={classes.outerWrapper}>
          <Grid container className={classes.innerWrapper}>
            <Grid item xs={8} className={classes.leftItem}>
              <Link style={{ color: "black", textDecoration: "none" }} to="/">
                <img src="/logo.png" className={classes.logo} alt="Logo" />
              </Link>
              <Link style={{ color: "black", textDecoration: "none" }} to="/">
                <Typography className={classes.link}>Home</Typography>
              </Link>
              <Link style={{ color: "black", textDecoration: "none" }} to="/smartLock">
                <Typography className={classes.link}>KTS Lock</Typography>
              </Link>
              <Link style={{ color: "black", textDecoration: "none" }} to="/smartBoard">
                <Typography className={classes.link}>KTS Board</Typography>
              </Link>
            </Grid>
            <Grid item xs={4} className={classes.rightItem}>
              <ClassicButton link="/contact" text="Kontaktiere uns" />
            </Grid>
          </Grid>
        </div>
      </>
    );
  } else {
    return (
      <>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            {/* <Typography variant="h6" noWrap className={classes.title}>
              Key Tech Systems
            </Typography> */}
            <IconButton style={{ padding: 8 }} color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="end" className={clsx(classes.menuButton, open && classes.hide)}>
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>{theme.direction === "ltr" ? <ChevronLeftIcon /> : <ChevronRightIcon />}</IconButton>
          </div>
          <img src="/logo.png" style={{ width: 140, margin: "0 auto 30px auto" }} />
          <Divider />
          <Button color="inherit">
            <Link style={{ color: "black", textDecoration: "none" }} to="/">
              <Typography>Startseite</Typography>
            </Link>
          </Button>
          <Button color="inherit">
            <Link style={{ color: "black", textDecoration: "none" }} to="/smartLock">
              <Typography>KTS Lock</Typography>
            </Link>
          </Button>
          <Button color="inherit">
            <Link style={{ color: "black", textDecoration: "none" }} to="/smartBoard ">
              <Typography>KTS Board</Typography>
            </Link>
          </Button>
          <Button color="inherit">
            <Link style={{ color: "black", textDecoration: "none" }} to="/contact">
              <Typography>Kontakt</Typography>
            </Link>
          </Button>
        </Drawer>
      </>
    );
  }
}

 
import { makeStyles, Typography, Grid, Paper } from "@material-ui/core";
import { useEffect } from "react";
import { ClassicButton } from "../buttons";

const useStyles = makeStyles((theme) => ({
  background: {
    width: "100%",
    paddingTop: 40,
    margin: "150px 0",
  },
  gridContainer: {
    width: "70%",
    minWidth: 900,
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      minWidth: "90%",
      width: "90%",
    },
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      marginBottom: 50,
    },
  },
  img: {
    height: 150,
  },
  header: {
    fontWeight: "bold",
    fontSize: 55,
    marginTop: 40,
    marginBottom: 70,
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: 38,
    },
  },
}));

export default function AccessControl() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.background}>
        <Typography className={classes.header}>Sie bestimmen, wer wann Zutritt hat</Typography>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={12} md={4} className={classes.gridItem}>
            <img src="/smartLock/groups.svg" alt="Groups" className={classes.img} />
            <Typography style={{ fontSize: 24, fontWeight: "bold", marginTop: 15 }}>Gruppen</Typography>
            <Typography style={{ fontSize: 18, width: 300, textAlign: "center" }}>Wählen Sie die Gruppen aus, welche Zutritt ins Gebäude haben sollen.</Typography>
          </Grid>
          <Grid item xs={12} md={4} className={classes.gridItem}>
            <img src="/smartLock/companies.svg" alt="companies" className={classes.img} />
            <Typography style={{ fontSize: 24, fontWeight: "bold", marginTop: 15 }}>Firmen</Typography>
            <Typography style={{ fontSize: 18, width: 300, textAlign: "center" }}>
              Ihnen ist eine Gruppe zu ungenau definiert? Dann entscheiden Sie, welche Firma eine Zutrittsberechtigung haben soll.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} className={classes.gridItem}>
            <img src="/smartLock/time.svg" alt="time" className={classes.img} />
            <Typography style={{ fontSize: 24, fontWeight: "bold", marginTop: 15 }}>Zeitgesteuert</Typography>
            <Typography style={{ fontSize: 18, width: 300, textAlign: "center" }}>Verschiedene externe Dienstleistungsunternehmen könne zeitgesteuerte Zutritt zum Gebäude erhalten.</Typography>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

 
import { makeStyles, Typography, Grid, Paper } from "@material-ui/core";
import { useEffect, useRef } from "react";
import { ClassicButton } from "../buttons";

const useStyles = makeStyles((theme) => ({
  success: {
    margin: "100px auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  successWrapper: {
    width: 350,
    height: 400,
    padding: 15,
    borderRadius: "16px 16px 0 16px",
    display: "flex",
    flexDirection: "column",
    border: "1px solid #ebd5d2",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: "white",
    transition: "300ms ease",
    "&:hover": {
      transform: "scale(0.9)",
    },
    [theme.breakpoints.down("md")]: {
      width: 300,
      height: 450,
    },
  },
  successBody: {
    transition: "height 0.5s cubic-bezier(0.25, 0.1, 0.2, 0.99) 0.5s",
    overflow: "hidden",
  },
  centerItems: {
    display: "flex",
    justifyContent: "center",
  },
  leftTextWrapper: {
    width: "80%",
    height: "fit-content",
    position: "sticky",
    top: "20px",
  },
  mainContainer: {
    width: 1200,
    margin: "0 auto",
    [theme.breakpoints.down("lg")]: {
      width: "90%",
    },
  },
}));

export default function Information() {
  const classes = useStyles();

  const keyFeaturesData = [
    {
      image: "/smartBoard/paper.svg",
      title: "Aktuelle Aushänge",
      body: "Top aktuelle und wichtige Informationen jederzeit und überall verfügbar.",
    },
    {
      image: "/smartBoard/service.svg",
      title: "Externe Dienstleister",
      body: "Auflistung aller empfohlenen Instandhaltungsfirmen durch die Hausverwaltung.",
    },
    {
      image: "/smartBoard/bus.svg",
      title: "Bus / Bim Zeiten",
      body: "Immer aktuelle Abfahrtszeiten online verfügbar.",
    },
    {
      image: "/smartBoard/contact.svg",
      title: "Kontaktinformationen",
      body: "Alle relevanten Kontaktpersonen übersichtlich dargestellt.",
    },
    {
      image: "/smartBoard/emergency.svg",
      title: "Notrufe",
      body: "Im Notfall immer die richtige Notrufnummer finden.",
    },
    {
      image: "/smartBoard/information.svg",
      title: "Informationen für Bewohner",
      body: "Jobbörse, Katwarn etc individuell auswählbar.",
    },
    {
      image: "/smartBoard/text.svg",
      title: "Laufschrift",
      body: "Schadensmeldungen übersichtlich dargestellt. Reduktion von Mehrfachanrufen.",
    },

    {
      image: "/smartBoard/doctor.svg",
      title: "Notdienste",
      body: "Für den Fall der Fälle sind die wichtigsten Notdienste angeführt.",
    },
    {
      image: "/smartBoard/weather.svg",
      title: "Aktuelle Daten",
      body: "Die aktuellen Daten wie: Datum, Uhrzeit, Wetter und Standort immer auf einem Blick.",
    },
  ];
  return (
    <>
      <div className={classes.success}>
        <Grid container className={classes.mainContainer}>
          <Grid item lg={4} md={6} xs={12} className={classes.centerItems}>
            <div className={classes.leftTextWrapper}>
              <Typography style={{ fontSize: 28, letterSpacing: 2, marginBottom: 25, color: "#EF6956" }}>- Informationen</Typography>
              <Typography style={{ fontSize: 38, lineHeight: 1, marginBottom: 25 }}>Informationen schön und übersichtlich dargestellt</Typography>
              <Typography style={{ fontSize: 18, marginBottom: 25 }}>
                Alle Aushänge schön und übersichtlich dargestellt und für alle leserlich. So geht keine wichtige Information mehr verloren
              </Typography>
              <ClassicButton link="/contact " text="Jetzt Bestellen" />
            </div>
          </Grid>
          <Grid item lg={8} md={6} xs={12}>
            <Grid container style={{ width: "95%", margin: "50px auto 0 auto" }}>
              {keyFeaturesData.map((s, index) => {
                return (
                  <>
                    <Grid item lg={6} xs={12} style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: 50 }}>
                      <div className={classes.successWrapper}>
                        <img src={s.image} alt="conversation" style={{ height: 100, margin: "0 auto" }} />
                        <div style={{ width: "90%", margin: "0 auto", height: "30%" }}>
                          <Typography style={{ fontSize: 26, fontWeight: 400 }}>{s.title}</Typography>
                          <div className={classes.successBody}>
                            <Typography style={{ fontSize: 18, fontWeight: 300, lineHeight: 2 }}>{s.body}</Typography>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

 
 
import { Grid, makeStyles, Typography, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    width: 900,
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      marginBottom: 50,
    },
  },
  gridItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    flexDirection: "column",
    marginBottom: 150,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 50,
    },
  },
  background: {
    marginTop: 100,
    width: "100%",
  },
  image: {
    height: 200,
    width: 200,
    margin: "0 auto",
  },
  body: {
    fontSize: 16,
    width: 350,
    [theme.breakpoints.down("sm")]: {
      textAlign: "start",
      width: "95%",
      margin: "0 auto",
    },
  },
}));

export default function Benefits() {
  const classes = useStyles();
  return (
    <>
      <div className={classes.background}>
        <div className={classes.gridContainer}>
          <Typography style={{ fontSize: 18, letterSpacing: 2, marginBottom: 25, color: "#EF6956" }}>- Unsere Mission</Typography>
          <Typography style={{ fontSize: 24, lineHeight: 1, marginBottom: 25 }}>Mit Key-Tech-Systems in die digitale Zukunft</Typography>
        </div>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={12} md={6} className={classes.gridItem}>
            <Typography style={{ fontSize: 24, fontWeight: "bold" }}>Automatisieren</Typography>
            <Typography className={classes.body}>Durch unsere IoT Gadgets werden sämtliche analogen Prozesse in sekundenschnelle verarbeitet und dokumentiert.</Typography>
          </Grid>
          <Grid item xs={12} md={6} className={classes.gridItem}>
            <img src="/automation.svg" alt="automatisieren" className={classes.image} />
          </Grid>
          <Grid item xs={12} md={6} className={classes.gridItem}>
            <img src="/digital.svg" alt="smartPhone" className={classes.image} />
          </Grid>
          <Grid item xs={12} md={6} className={classes.gridItem}>
            <Typography style={{ fontSize: 24, fontWeight: "bold" }}>Digitalisieren</Typography>
            <Typography className={classes.body}>
              Die Digitalisierung erleichtert den Alltag. Da alle Daten per Knofdruck anzeigbar / managebar sind, erspart dies Zeit und Ressourcen.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} className={classes.gridItem}>
            <Typography style={{ fontSize: 24, fontWeight: "bold" }}>Vernetzen</Typography>
            <Typography className={classes.body}>
              Mithilfe der Anbindung an unsere Cloudtechnologie spielen sämtliche unserer Produkte im Einklang zusammen. Dies erleichtert unseren Alltag.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} className={classes.gridItem}>
            <img src="/connection.svg" alt="verbinden" className={classes.image} />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import reportWebVitals from "./reportWebVitals";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import SmartBoard from "./smartBoard";
import SmartLock from "./smartLock";
import Contact from "./contact";
import NavBar from "./components/Navbar";
import Footer from "./components/footer";
import AGB from "./agb";
import Impressum from "./impressum";
import Datenschutz from "./datenschutz";
import ScrollToTop from "./scrollToTop";
import Cookies from "./components/cookies";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ScrollToTop>
        <NavBar />
        <Routes>
          <Route exact path="/" element={<App />} />
          <Route path="/smartBoard/" element={<SmartBoard />} />
          <Route path="/smartLock/" element={<SmartLock />} />
          <Route path="/contact/" element={<Contact />} />
          <Route path="/agb/" element={<AGB />} />
          <Route path="/impressum/" element={<Impressum />} />
          <Route path="/datenschutz/" element={<Datenschutz />} />
          {/* <Redirect to="/"/> */}
        </Routes>
        <Footer />
        <Cookies />
      </ScrollToTop>
    </Router>
    {/* <App /> */}
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

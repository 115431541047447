import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    width: "fit-content",
    padding: "14px 28px",
    backgroundColor: "#EF6956",
    border: "2px solid #EF6956",
    borderRadius: 8,
    color: "white",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "white",
      color: "black",
    },
  },
}));

export function ClassicButton({ text, link }) {
  const classes = useStyles();

  return (
    <Link style={{color: "black", textDecoration: "none"}} to={link} style={{ textDecoration: "none" }}>
      <div className={classes.buttonWrapper}>
        <Typography style={{ fontSize: 14, width: "fit-content" }}>{text}</Typography>
      </div>
    </Link>
  );
}

 
 
import { Grid, makeStyles, Typography, TextField } from "@material-ui/core";
import { ClassicButton } from "../buttons";

const useStyles = makeStyles((theme) => ({
  background: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 50,
    borderBottom: "1px solid #EF6956",
  },
  centerText: {
    maxWidth: 600,
    width: "85%",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
    },
  },
  image: {
    position: "absolute",
    height: 300,
    top: 0,
    transform: "rotate(90deg)",
    opacity: 0.3,
  },
  gridItem: {
    width: "80%",
    margin: "0 auto",
  },
  leftItem: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  header: {
    fontSize: 54,
    lineHeight: 1,
    marginBottom: 25,
    [theme.breakpoints.down("sm")]: {
      fontSize: 44,
    },
  },
}));

export default function Hero() {
  const classes = useStyles();
  return (
    <>
      <div className={classes.background}>
        <Grid container className={classes.gridItem}>
          <Grid item lg={6} xs={12} className={classes.leftItem}>
            <div className={classes.centerText}>
              <Typography style={{ fontSize: 28, letterSpacing: 2, marginBottom: 25, color: "#EF6956" }}>- KTS Board</Typography>
              <Typography className={classes.header}>Alle Informationen auf einem Blick</Typography>
              <Typography style={{ fontSize: 18, marginBottom: 25 }}>
                Jedes Informationsschreiben wird ab sofort nur mehr digital - mittels Hausverwaltungsvorlage - auf das KTS Board per Mausklick übertragen. <br /> Dadurch verringern sich die Aufwände
                pro Haus für ein Informationsschreiben auf ein Minimum. Es ist kein Papieraushang mehr notwendig.
              </Typography>
              <ClassicButton text="Jetzt Beraten lassen" link="/contact " />
            </div>
          </Grid>
          <Grid item lg={6} xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img src="/smartBoard/mockup.png" alt="tv" style={{ width: "120%", margin: "50px 0" }} />
          </Grid>
        </Grid>
      </div>
      {/* <img src="/smartBoard/leaf.svg" className={classes.image} alt="leaf" /> */}
    </>
  );
}

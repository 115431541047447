 
 
import { Grid, makeStyles, Typography, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  background: {
    padding: "50px 0",
    backgroundColor: "#321b18",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  },
  text: {
    fontSize: 45,
    textAlign: "center",
    maxWidth: 1200,
    // width: "90%",
    [theme.breakpoints.down("md")]:{
      fontSize: 38, 
    }
  },
}));

export default function Quote() {
  const classes = useStyles();
  return (
    <>
      <div className={classes.background}>
        <hr style={{ width: 200, marginBottom: 50 }} />
        <Typography className={classes.text}>“Intelligente Technik und herausragende Qualität machen uns zur ersten Wahl für Digitalisierungen.“</Typography>
        <Typography style={{ fontSize: 18, marginTop: 50 }}>- Alexander Pilz / CEO der KTS</Typography>
        <hr style={{ width: 200, marginTop: 50 }} />
      </div>
    </>
  );
}

 
import { makeStyles, Typography, Grid, Paper } from "@material-ui/core";
import { useEffect } from "react";
import { ClassicButton } from "../buttons";

const useStyles = makeStyles((theme) => ({
  background: {
    width: "100%",
    paddingTop: 40,
    marginTop: 150,
  },
  gridContainer: {
    width: 850,
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      width: "95%",
    },
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  leftItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      marginBottom: 30,
    },
  },
  innerWrapper: {
    width: 500,
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
}));

export default function TwoFactorApp() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.background}>
        <Grid container className={classes.gridContainer}>
          <Grid item md={7} xs={12} className={classes.leftItem}>
            <div className={classes.innerWrapper}>
              <Typography style={{ fontWeight: "bold", fontSize: 55, marginTop: 40, marginBottom: 20 }}>Doppelte Sicherheit</Typography>
              <Typography style={{ fontSize: 26, fontWeight: "bold" }}>Zwei-Faktor Authentifizierung</Typography>
              <Typography style={{ fontSize: 20, marginTop: 5 }}>
                Mithilfe der eigens entwickelten zwei-Faktor Authentifizierungs-App sind Zutritte für externe Dienstleistungsunternehmen gewährleistet.{" "}
              </Typography>
              <Typography style={{ marginTop: 30, fontSize: 26, fontWeight: "bold" }}>Einfache Bedienung</Typography>
              <Typography style={{ fontSize: 20, marginTop: 5 }}>Durch die biometrische Authentifikation ist eine unkomplizierte Handhabung der App möglich.</Typography>
            </div>
          </Grid>
          <Grid item md={5} xs={12} className={classes.gridItem}>
            <img src="/smartLock/mockupPhone.png" alt="2FA App preview" height={600} />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

import AccessControl from "./components/smartLock/accessControl";
import Battery from "./components/smartLock/battery";
import Hero from "./components/smartLock/hero";
import Mms from "./components/smartLock/mms";
import TwoFactorApp from "./components/smartLock/tfaapp";

export default function SmartLock() {
  return (
    <>
      <Hero />
      <TwoFactorApp />
      <Mms />
      <AccessControl />
      <Battery />
    </>
  );
}

 
import { makeStyles, Typography, Grid, Paper } from "@material-ui/core";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  profit: {
    margin: "100px auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  profitWrapper: {
    width: "80%",
    height: 370,
    textAlign: "center",
    padding: 20,
    borderRadius: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  profitImageWrapper: {
    height: 50,
    width: 50,
  },
  profitBody: {
    transition: "height 0.5s cubic-bezier(0.25, 0.1, 0.2, 0.99) 0.5s",
    overflow: "hidden",
    marginTop: 15,
  },
  gridContainer: {
    width: 1180,
    margin: "50px auto 0 auto",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
}));

export default function ClientProfit() {
  const classes = useStyles();

  const profitData = [
    {
      image: "/sketch.png",
      title: "Design",
      body: "state of the Art Technologie, ",
    },
    {
      image: "/sketch.png",
      title: "Vorteile",
      body: "Digitale Vernetzung, reduktion der Betriebskosten, community Management, Push Nachrichten, Kontatkdaten",
    },
    {
      image: "/sketch.png",
      title: "Nutzen",
      body: "mehrwert für Gebäudenutzer,",
    },
  ];

  return (
    <>
      <div className={classes.profit}>
        <div style={{ textAlign: "center" }}>
          <Typography style={{ fontSize: 36 }}>So profitieren Sie</Typography>
        </div>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={12} sm={4} style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginBottom: 50 }}>
            <div className={classes.profitWrapper}>
              <Typography style={{ fontSize: 24 }}>Mehrwert</Typography>
              <img src="/smartBoard/plus.svg" alt="sketch" style={{ height: 100, width: 100, margin: "0 auto" }} />
              <div className={classes.profitBody}>
                <Typography style={{ fontSize: 18, fontWeight: 300 }}>
                  Kein Papieraushang erforderlich <br /> Schonung von Ressourcen
                  <br />
                  Zeitersparnis <br /> Online Schadensmeldungen
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginBottom: 50 }}>
            <div className={classes.profitWrapper}>
              <Typography style={{ fontSize: 24 }}>Vorteile</Typography>
              <img src="/smartBoard/benefits.svg" alt="sketch" style={{ height: 100, width: 100, margin: "0 auto" }} />
              <div className={classes.profitBody}>
                <Typography style={{ fontSize: 18, fontWeight: 300 }}>
                  Digitale Vernetzung <br /> Reduktion der Betriebskosten <br /> Community Management <br /> Push Nachrichten
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginBottom: 50 }}>
            <div className={classes.profitWrapper}>
              <Typography style={{ fontSize: 24 }}>Nutzen</Typography>
              <img src="/sketch.svg" alt="sketch" style={{ height: 100, width: 100, margin: "0 auto" }} />
              <div className={classes.profitBody}>
                <Typography style={{ fontSize: 18, fontWeight: 300 }}>
                  Kommunikation mit allen Hausbewohnern <br /> Einfache verwaltung der Vorlagen <br /> Öffnung der Hauseingangstüre
                  <br />
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

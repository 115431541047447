import * as React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default function Impressum() {
  return (
    <div style={{ height: "100%", width: "100%", backgroundColor: "lightgray", paddingBottom: 100 }}>
      <Typography style={{ textAlign: "center", paddingTop: 100, marginBottom: 50, fontSize: 48 }}>Impressum</Typography>
      <div style={{ width: "90%", margin: "0 auto" }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography>Anschrift</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              KTS Key-Tech-Systems GmbH &amp; Co KG
              <br />
              Erstellung und Vertrieb von Software und Hardware
              <br />
              Geschäftsführer: Alexander Pilz, Alwin Günzberg, ppa Günther Zmuck MBA
              <br />
              <b>Umsatzsteuer-Identifikationsnummer</b>: XXXX
              <br />
              <b>Firmenbuchnummer</b>: FN 568330f
              <br />
              <b>Firmenbuchgericht</b>: Graz
              <br />
              <b>Sitz</b>: 8010 Graz, Plüddemanngasse 67b
              <br />
              <b>Tel</b>.: 0316-382200-44
              <br />
              <b>E-Mail</b>: office@ktsystems.at
              <br />
              <b>Mitglied der Wirtschaftskammer Steiermark</b>
              <br />
              <b>Berufsrecht: Gewerbeordnung:</b> www.ris.bka.gv.at
              <br />
              Aufsichtsbehörde: Magistrat Graz
              <br />
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography>Haftung</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Der Autor dieser Webseiten ist bestrebt, die hier angebotenen Informationen nach bestem Wissen und Gewissen vollständig und richtig darzustellen und aktuell zu halten. Dennoch kann er
              keinerlei Haftung für Schäden übernehmen, die sich aus der Nutzung der angebotenen Informationen ergeben können – auch wenn diese auf die Nutzung von allenfalls unvollständigen bzw.
              fehlerhaften Informationen zurückzuführen sind. Verweise auf fremde Webseiten liegen außerhalb des Verantwortungsbereiches des Autors. Eine Haftung für die Inhalte von verlinkten Seiten
              ist ausgeschlossen, zumal der Autor keinen Einfluss auf Inhalte wie Gestaltung von gelinkten Seiten hat. Für Inhalte von Seiten, auf welche von Seiten dieser Homepage verwiesen wird,
              haftet somit allein der Anbieter dieser fremden Webseiten – niemals jedoch derjenige, der durch einen Link auf fremde Publikationen und Inhalte verweist. Sollten gelinkte Seiten
              (insbesondere durch Veränderung der Inhalte nach dem Setzen des Links) illegale, fehlerhafte, unvollständige, beleidigende oder sittenwidrige Informationen beinhalten und wird der Autor
              dieser Seite auf derartige Inhalte von gelinkten Seiten aufmerksam (gemacht), so wird er einen Link auf derartige Seiten unverzüglich unterbinden.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography>Urheberrecht</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Der Inhalt der vom Autor selbst erstellten Inhalte dieser Seiten ist urheberrechtlich geschützt. Jede den Bestimmungen des Urheberrechtsgesetzes widersprechende Verwendung jeglicher
              Inhalte dieser Webseiten – insbesondere die weitergehende Nutzung wie beispielsweise die Veröffentlichung, Vervielfältigung und jede Form von gewerblicher Nutzung sowie die Weitergabe an
              Dritte – auch in Teilen oder in überarbeiteter Form – ohne ausdrückliche Zustimmung des Autors ist untersagt. Davon ausgenommen sind ausdrücklich zum Download gekennzeichnete Dateien.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography>Informationsgehalt</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Die Informationen dieser Webseiten können ohne vorherige Ankündigung geändert, entfernt oder ergänzt werden. Der Autor kann daher keine Garantie für die Korrektheit, Vollständigkeit oder
              Qualität und letzte Aktualität der bereitgestellten Informationen geben.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography>Rechtswirksamkeit</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Durch Nutzung dieser Webseiten unterliegt der Nutzer den gegenständlichen Benützungsbedingungen. Diese sind Teil des WWW-Angebotes. Sofern Teile oder einzelne Formulierungen der
              Benützungsbedingungen der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile der Benützungsbedingungen in ihrem Inhalt und ihrer
              Gültigkeit davon unberührt.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography>Fotocredits</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Die Informationen dieser Webseiten können ohne vorherige Ankündigung geändert, entfernt oder ergänzt werden. Der Autor kann daher keine Garantie für die Korrektheit, Vollständigkeit oder
              Qualität und letzte Aktualität der bereitgestellten Informationen geben.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography>Fotocredits</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Auf dieser Website befindet sich Bildmaterial von iconScout, flatIcon und unsplash</Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}

 
import { makeStyles, Typography, Grid, Paper } from "@material-ui/core";
import { useEffect } from "react";
import { ClassicButton } from "../buttons";

const useStyles = makeStyles((theme) => ({
  background: {
    width: "100%",
    paddingTop: 40,
  },
  innerWrapper: {
    width: "60%",
    margin: "0 auto",
  },
  iconWrapper: {
    width: "80%",
    margin: "150px auto 0 auto",
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  icons: {
    height: 100,
  },
  image: {
    width: 500,
    [theme.breakpoints.down(500)]: {
      width: "90%",
    },
  },
  iconText: {
    textAlign: "center",
    width: 300,
    fontSize: 20,
    [theme.breakpoints.down("md")]: {
      marginBottom: 50,
    },
  },
}));

export default function Hero() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.background}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <div className={classes.innerWrapper}>
              <Typography style={{ fontSize: 28, letterSpacing: 2, marginBottom: 25, color: "#EF6956" }}>- KTS Lock</Typography>
              <Typography style={{ fontSize: 38, lineHeight: 1 }}>Wir haben nicht das Schloss neu erfunden...</Typography>
              <Typography style={{ fontSize: 38, lineHeight: 1, marginBottom: 25 }}>Nur die Sicherheit dahinter</Typography>
              <Typography style={{ fontSize: 18, marginBottom: 25 }}>Um die Sicherheit in Ihrem Gebäude zu gewährleisten, setzten wir auf innovative Zutrittssysteme.</Typography>
              <ClassicButton link="/contact " text="Jetzt Bestellen" />
            </div>
          </Grid>
          <Grid item xs={12} md={6} className={classes.gridItem}>
            <img src="/smartLock/keycard-scan-system.svg" alt="keyCard Opening" className={classes.image} />
          </Grid>
        </Grid>
        <Grid container className={classes.iconWrapper}>
          <Grid item xs={12} md={4} className={classes.gridItem}>
            <img src="/smartLock/authentication.svg" alt="img" className={classes.icons} />
            <Typography className={classes.iconText}>Doppelte Sicherheit mit einer zwei-Faktor Authentifizierung</Typography>
          </Grid>
          <Grid item xs={12} md={4} className={classes.gridItem}>
            <img src="/smartLock/realtime.svg" alt="img" className={classes.icons} />
            <Typography className={classes.iconText}>Echtzeit Zutrittsberechtigungsvergabe</Typography>
          </Grid>
          <Grid item xs={12} md={4} className={classes.gridItem}>
            <img src="/smartLock/battery-charging.svg" alt="img" className={classes.icons} />
            <Typography style={{ textAlign: "center", width: 300, fontSize: 20 }}>Stromausfallsicher mithilfe einer eingebauten Batterie</Typography>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

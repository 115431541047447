import { Grid, makeStyles, Typography, Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  background: {
    margin: "100px 0",
  },
  gridContainer: {
    width: "95%",
    margin: "0 auto",
  },
  gridItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  body: {
    width: "95%",
    maxWidth: 650,
  },
  image: {
    height: "150px",
    width: "150px",
    borderRadius: 500,
    backgroundSize: "cover",
    margin: "0 auto 25px auto",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  gridContainerImages: {
    width: 400,
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      width: 300,
    },
  },
}));

export default function Quote() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.background}>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={12} md={6} className={classes.gridItem}>
            <div className={classes.body}>
              <Typography style={{ fontSize: 24, fontWeight: "bolder" }}>Unsere Motivation</Typography>
              <Typography style={{ fontSize: 18, marginBottom: 10 }}>
                Wir sind ein motiviertes und erfahrenes Unternehmen, welches sich zur Aufgabe gesetzt hat, einen wichtigen Schritt zur Digitalisierung beizutragen.
              </Typography>
              <Typography style={{ fontSize: 18, marginBottom: 10 }}>Für uns ist &quot;Digitalisierung&quot; nicht nur ein Wort, sondern unsere Motivation und Mission.</Typography>
              <Typography style={{ fontSize: 18, marginBottom: 10 }}>Aus diesem Grund erstellen wir hochmoderne Hardwarekomponenten, die unser vernetztes Leben vereinfachen.</Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6} className={classes.gridItem}>
            <Grid container className={classes.gridContainerImages}>
              <Grid item xs={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Tooltip title="Kovaceivc Marko">
                  <div className={classes.image} style={{ backgroundImage: "url('/we/marko.jpeg')" }}></div>
                </Tooltip>
              </Grid>
              <Grid item xs={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Tooltip title="Kretschmer Florian">
                  <div className={classes.image} style={{ backgroundImage: "url('/we/flo.jpeg')" }}></div>
                </Tooltip>
              </Grid>
              <Grid item xs={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Tooltip title="Pilz Alexander">
                  <div className={classes.image} style={{ backgroundImage: "url('/we/alex.jpeg')" }}></div>
                </Tooltip>
              </Grid>
              <Grid item xs={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Tooltip title="Zmuck Günter">
                  <div className={classes.image} style={{ backgroundImage: "url('/we/gue.jpeg')" }}></div>
                </Tooltip>
              </Grid>
              <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Tooltip title="Alwin Günzberg">
                  <div className={classes.image} style={{ backgroundImage: "url('/we/alwin.jpeg')" }}></div>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

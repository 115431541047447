 
 
import { Grid, makeStyles, Typography, TextField } from "@material-ui/core";
import { useState, useEffect } from "react";
import Lottie from "react-lottie";
import * as HeroLottie from "../../lottie/hero.json";
import { ClassicButton } from "../buttons";

const useStyles = makeStyles((theme) => ({
  background: {
    width: "70%",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    paddingTop: 100,
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  textField: {
    backgroundColor: "white",
    padding: 10,
    width: 400,
    marginBottom: 30,
  },
  buttonWrapper: {
    backgroundColor: "#EF6956",
    border: "1px solid #EF6956",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    width: 200,
    marginLeft: 30,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
    },
  },
  header: {
    fontSize: 88,
    lineHeight: 0.95,
    fontWeight: 300,
    marginBottom: 25,
    [theme.breakpoints.down("md")]: {
      fontSize: 65,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 55,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 38,
    },
  },
  subHeader: {
    fontSize: 18,
    marginBottom: 25,
    width: 790,
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  lottie: {
    width: 750,
    margin: "0 auto",
  },
}));

export default function Hero() {
  const classes = useStyles();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: HeroLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <div className={classes.background}>
        <Typography style={{ fontSize: 28, letterSpacing: 2, marginBottom: 25, color: "#EF6956" }}>- Key-Tech-Systems</Typography>
        <Typography className={classes.header}>Wir entwickeln modernste Hardwaretechnik in Österreich</Typography>
        <Typography className={classes.subHeader}>Alle Aushänge schön und übersichtlich dargestellt und für alle leserlich. So geht keine wichtige Information mehr verloren</Typography>
        <ClassicButton link="/contact" text="Jetzt Bestellen" />
      </div>
    </>
  );
}

import * as React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default function AGB() {
  return (
    <div style={{ height: "100%", width: "100%", backgroundColor: "lightgray", paddingBottom: 100 }}>
      <Typography style={{ textAlign: "center", paddingTop: 100, marginBottom: 50, fontSize: 48 }}>AGB</Typography>
      <div style={{ width: "90%", margin: "0 auto" }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography>1. Geltung, Vertragsabschluss</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <b>1.1.</b>Die Firma KTS Key-Tech-Systems GmbH & Co KG (im Folgenden „AG“ genannt) erbringt ihre Leistungen ausschließlich auf der Grundlage der nachfolgenden Allgemeinen
              Geschäftsbedingungen (AGB). Diese gelten für alle Rechtsbeziehungen zwischen der „AG“ und dem Kunden, selbst wenn nicht ausdrücklich auf sie Bezug genommen wird. Die AGB sind
              ausschließlich für Rechtsbeziehung mit Unternehmern anwendbar, sohin B2B.
              <br />
              <b>1.2.</b>Maßgeblich ist jeweils die zum Zeitpunkt des Vertragsschlusses gültige Fassung. Abweichungen von diesen sowie sonstige ergänzende Vereinbarungen mit dem Kunden sind nur
              wirksam, wenn sie von der „AG“ schriftlich bestätigt werden.
              <br />
              <b>1.3.</b>Allfällige Geschäftsbedingungen des Kunden werden, selbst bei Kenntnis, nicht akzeptiert, sofern nicht im Einzelfall ausdrücklich und schriftlich anderes vereinbart wird. AGB
              des Kunden widerspricht die „AG“ ausdrücklich. Eines weiteren Widerspruchs gegen AGB des Kunden durch die „AG“ bedarf es nicht.
              <br />
              <b>1.4.</b>Änderungen der AGB werden dem Kunden bekannt gegeben und gelten als vereinbart, wenn der Kunde den geänderten AGB nicht schriftlich binnen 14 Tagen widerspricht; auf die
              Bedeutung des Schweigens wird der Kunde in der Verständigung ausdrücklich hingewiesen.
              <br /> <b>1.5.</b>Sollten einzelne Bestimmungen dieser Allgemeinen Geschäftsbedingungen unwirksam sein, so berührt dies die Verbindlichkeit der übrigen Bestimmungen und der unter ihrer
              Zugrundelegung geschlossenen Verträge nicht. Die unwirksame Bestimmung ist durch eine wirksame, die dem Sinn und Zweck am nächsten kommt, zu ersetzen.
              <br /> <b>1.6.</b>Die Angebote der „AG“ sind freibleibend und unverbindlich.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
            <Typography>2. Social Media Kanäle</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Die „AG“ weist den Kunden vor Auftragserteilung ausdrücklich darauf hin, dass die Anbieter von „Social-Media-Kanälen“ (z.B. facebook, im Folgenden kurz: Anbieter) es sich in ihren
              Nutzungsbedingungen vorbehalten, Werbeanzeigen und -auftritte aus beliebigen Grund abzulehnen oder zu entfernen. Die Anbieter sind demnach nicht verpflichtet, Inhalte und Informationen
              an die Nutzer weiterzuleiten. Es besteht daher das von der „AG“ nicht kalkulierbare Risiko, dass Werbeanzeigen und -auftritte grundlos entfernt werden. Im Fall einer Beschwerde eines
              anderen Nutzers wird zwar von den Anbietern die Möglichkeit einer Gegendarstellung eingeräumt, doch erfolgt auch in diesem Fall eine sofortige Entfernung der Inhalte. Die Wiedererlangung
              des ursprünglichen, rechtmäßigen Zustandes kann in diesem Fall einige Zeit in Anspruch nehmen. Die „AG“ arbeitet auf der Grundlage dieser Nutzungsbedingungen der Anbieter, auf die sie
              keinen Einfluss hat, und legt diese auch dem Auftrag des Kunden zu Grunde. Ausdrücklich anerkennt der Kunde mit der Auftragserteilung, dass diese Nutzungsbedingungen die Rechte und
              Pflichten eines allfälligen Vertragsverhältnisses (mit-)bestimmen. Die „AG“ beabsichtigt, den Auftrag des Kunden nach bestem Wissen und Gewissen auszuführen und die Richtlinien von
              „Social Media Kanälen“ einzuhalten. Aufgrund der derzeit gültigen Nutzungsbedingungen und der einfachen Möglichkeit jedes Nutzers, Rechtsverletzungen zu behaupten und so eine Entfernung
              der Inhalte zu erreichen, kann die Firma „AG“ aber nicht dafür einstehen, dass die beauftragte Kampagne auch jederzeit abrufbar ist.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
            <Typography>3. Konzept- und Ideenschutz</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Hat der potentielle Kunde die „AG“ vorab bereits eingeladen, ein Konzept zu erstellen, und kommt die „AG“ dieser Einladung noch vor Abschluss des Hauptvertrages nach, so gilt
              nachstehende Regelung:
              <br />
              <b>3.1.</b>Bereits durch die Einladung und die Annahme der Einladung durch die „AG“ treten der potentielle Kunde und die „AG“ in ein Vertragsverhältnis („Pitching-Vertrag“). Auch diesem
              Vertrag liegen die AGB zu Grunde.
              <br />
              <b> 3.2.</b>Der potentielle Kunde anerkennt, dass die „AG“ bereits mit der Konzepterarbeitung kostenintensive Vorleistungen erbringt, obwohl er selbst noch keine Leistungspflichten
              übernommen hat.
              <br />
              <b> 3.3. </b>Das Konzept untersteht in seinen sprachlichen und grafischen Teilen, soweit diese Werkhöhe erreichen, dem Schutz des Urheberrechtsgesetzes. Eine Nutzung und Bearbeitung
              dieser Teile ohne Zustimmung der „AG“ ist dem potentiellen Kunden schon auf Grund des Urheberrechtsgesetzes nicht gestattet.
              <br />
              <b> 3.4.</b>Das Konzept enthält darüber hinaus werberelevante Ideen, die keine Werkhöhe erreichen und damit nicht den Schutz des Urheberrechtsgesetzes genießen. Diese Ideen stehen am
              Anfang jedes Schaffensprozesses und können als zündender „funke“ alles später Hervorgebrachten und somit als Ursprung von Vermarktungsstrategie definiert werden. Daher sind jene Elemente
              des Konzeptes geschützt, die eigenartig sind und der Vermarktungsstrategie ihre charakteristische Prägung geben. Als Idee im Sinne dieser Vereinbarung werden insbesondere
              Werbeschlagwörter, Werbetexte, Grafiken und Illustrationen, Werbemittel usw. angesehen, auch wenn sie keine Werkhöhe erreichen.
              <br />
              <b> 3.5.</b>Der potentielle Kunde verpflichtet sich, es zu unterlassen, diese von der „AG“ im Rahmen des Konzeptes präsentierten kreativen Werbeideen außerhalb des Korrektivs eines
              später abzuschließenden Hauptvertrages wirtschaftlich zu verwerten bzw. verwerten zu lassen oder zu nutzen bzw. nutzen zu lassen.
              <br />
              <b> 3.6.</b>Sofern der potentielle Kunde der Meinung ist, dass ihm von der „AG“ Ideen präsentiert wurden, auf die er bereits vor der Präsentation gekommen ist, so hat er dies der „AG“
              binnen 14 Tagen nach dem Tag der Präsentation per E-Mail unter Anführung von Beweismitteln, die eine zeitliche Zuordnung erlauben, bekannt zu geben.
              <br />
              <b> 3.7.</b>Im gegenteiligen Fall gehen die Vertragsparteien davon aus, dass die „AG“ dem potentiellen Kunden eine für ihn neue Idee präsentiert hat. Wird die Idee vom Kunden verwendet,
              so ist davon auszugehen, dass die „AG“ dabei verdienstlich wurde. <br />
              <b>3.8.</b>Der potentielle Kunde kann sich von seinen Verpflichtungen aus diesem Punkt durch Zahlung einer angemessenen Entschädigung zuzüglich 20 % Umsatzsteuer befreien. Die Befreiung
              tritt erst nach vollständigem Eingang der Zahlung der Entschädigung bei der „AG“ ein.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
            <Typography>4. Leistungsumfang, Auftragsabwicklung und Mitwirkungspflichten des Kunden</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <br />
              <b>4.1.</b>Der Umfang der zu erbringenden Leistungen ergibt sich aus der Leistungsbeschreibung im Vertrag oder einer allfälligen Auftragsbestätigung durch „AG“, sowie dem allfälligen
              Briefingprotokoll („Angebotsunterlagen“). Nachträgliche Änderungen des Leistungsinhaltes bedürfen der schriftlichen Bestätigung durch die „AG“. Innerhalb des vom Kunden vorgegeben
              Rahmens besteht bei der Erfüllung des Auftrages Gestaltungsfreiheit der „AG“.
              <br />
              <b>4.2.</b>Alle Leistungen der „AG“ (insbesondere alle Vorentwürfe, Skizzen, Reinzeichnungen, Bürstenabzüge, Blaupausen, Kopien, Farbabdrucke und elektronische Dateien) sind vom Kunden
              zu überprüfen und von ihm binnen drei Werktagen ab Eingang beim Kunden freizugeben. Nach Verstreichen dieser Frist ohne Rückmeldung des Kunden gelten sie als vom Kunden genehmigt.
              <b />
              <br />
              <b>4.3</b>Der Kunde wird der „AG“ zeitgerecht und vollständig alle Informationen und Unterlagen zugänglich machen, die für die Erbringung der Leistung erforderlich sind. Er wird sie
              von allen Umständen informieren, die für die Durchführung des Auftrages von Bedeutung sind, auch wenn diese erst während der Durchführung des Auftrages bekannt werden. Der Kunde trägt
              den Aufwand, der dadurch entsteht, dass Arbeiten infolge seiner unrichtigen, unvollständigen oder nachträglich geänderten Angaben von der „AG“ wiederholt werden müssen oder verzögert
              werden.
              <br />
              <b> 4.4.</b>Der Kunde ist weiters verpflichtet, die für die Durchführung des Auftrages zur Verfügung gestellten Unterlagen (Fotos, Logos etc.) auf allfällige Urheber-, Marken-,
              Kennzeichenrechte oder sonstige Rechte Dritter zu prüfen (Rechteclearing) und garantiert, dass die Unterlagen frei von Rechten Dritter sind und daher für den angestrebten Zweck
              eingesetzt werden können. Die „AG“ haftet im Falle bloß leichter Fahrlässigkeit oder nach Erfüllung ihrer Warnpflicht – jedenfalls im Innenverhältnis zum Kunden - nicht wegen einer
              Verletzung derartiger Rechte Dritter durch zur Verfügung gestellte Unterlagen. Wird die „AG“ wegen einer solchen Rechtsverletzung von einem Dritten in Anspruch genommen, so hält der
              Kunde die „AG“ schad- und klaglos; er hat ihr sämtliche Nachteile zu ersetzen, die ihr durch eine Inanspruchnahme Dritter entstehen, insbesondere die Kosten einer angemessenen
              rechtlichen Vertretung. Der Kunde verpflichtet sich, die „AG“ bei der Abwehr von allfälligen Ansprüchen Dritter zu unterstützen. Der Kunde stellt der „AG“ hierfür unaufgefordert
              sämtliche Unterlagen zur Verfügung.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
            <Typography>5. Fremdleistungen / Beauftragung Dritter</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <br />
              <b>5.1.</b>Die „AG“ ist nach freiem Ermessen berechtigt, die Leistung selbst auszuführen, sich bei der Erbringung von vertragsgegenständlichen Leistungen sachkundiger Dritter als
              Erfüllungsgehilfen zu bedienen und/oder derartige Leistungen zu substituieren („Fremdleistung“).
              <br />
              <b> 5.2.</b>Die Beauftragung von Dritten im Rahmen einer Fremdleistung erfolgt entweder im eigenen Namen oder im Namen des Kunden. Die „AG“ wird diesen Dritten sorgfältig auswählen und
              darauf achten, dass dieser über die erforderliche fachliche Qualifikation verfügt.
              <br />
              <b> 5.3.</b>In Verpflichtungen gegenüber Dritten, die über die Vertragslaufzeit hinausgehen, hat der Kunde einzutreten. Das gilt ausdrücklich auch im Falle einer Kündigung des
              Vertrages aus wichtigem Grund.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
            <Typography>6. Termine</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <b>6.1.</b>Angegebene Liefer- oder Leistungsfristen gelten, sofern nicht ausdrücklich als verbindlich vereinbart, nur als annähernd und unverbindlich. Verbindliche Terminabsprachen sind
              schriftlich festzuhalten bzw. von der „AG“ schriftlich zu bestätigen. <br />
              <b> 6.2.</b>Verzögert sich die Lieferung/Leistung der „AG“ aus Gründen, die sie nicht zu vertreten hat, wie z.B. Ereignisse höherer Gewalt und andere unvorhersehbare, mit zumutbaren
              Mitteln nicht abwendbare Ereignisse, ruhen die Leistungsverpflichtungen für die Dauer und im Umfang des Hindernisses und verlängern sich die Fristen entsprechend. Sofern solche
              Verzögerungen mehr als zwei Monate andauern, sind der Kunde und die „AG“ berechtigt, vom Vertrag zurückzutreten.
              <br />
              <b> 6.3. </b>Befindet sich die „AG“ in Verzug, so kann der Kunde vom Vertrag nur zurücktreten, nachdem er der „AG“ schriftlich eine angemessene Nachfrist von zumindest 14 Tagen gesetzt
              hat und diese fruchtlos verstrichen ist. Schadenersatzansprüche des Kunden wegen Nichterfüllung oder Verzug sind ausgeschlossen, ausgenommen bei Nachweis von Vorsatz oder grober
              Fahrlässigkeit.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
            <Typography>7. Vorzeitige Auflösung</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <b>7.1.</b>Die „AG“ ist berechtigt, den Vertrag aus wichtigen Gründen mit sofortiger Wirkung aufzulösen. Ein wichtiger Grund liegt insbesondere vor, wenn a) die Ausführung der Leistung
              aus Gründen, die der Kunde zu vertreten hat, unmöglich wird oder trotz Setzung einer Nachfrist von 14 Tagen weiter verzögert wird; b) der Kunde fortgesetzt, trotz schriftlicher Abmahnung
              mit einer Nachfristsetzung von 14 Tagen, gegen wesentliche Verpflichtungen aus diesem Vertrag, wie z.B. Zahlung eines fällig gestellten Betrages oder Mitwirkungspflichten, verstößt. c)
              berechtigte Bedenken hinsichtlich der Bonität des Kunden bestehen und dieser auf Begehren der „AG“ weder Vorauszahlungen leistet und auch keine Anzahlung geleistet hat.
              <br />
              <b> 7.2.</b>Der Kunde ist berechtigt, den Vertrag aus wichtigen Gründen ohne Nachfristsetzung aufzulösen. Ein wichtiger Grund liegt insbesondere dann vor, wenn die „AG“ fortgesetzt,
              trotz schriftlicher Abmahnung mit einer angemessenen Nachfrist von zumindest 14 Tagen zur Behebung des Vertragsverstoßes gegen wesentliche Bestimmungen aus diesem Vertrag verstößt.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
            <Typography>8. Honorar</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <b>8.1. </b>Wenn nichts anderes vereinbart ist, entsteht der Honoraranspruch der „AG“ für jede einzelne Leistung, sobald diese erbracht wurde. Die „AG“ ist berechtigt, zur Deckung ihres
              Aufwandes Vorschüsse zu verlangen. Bei Aufträgen die sich über einen längeren Zeitraum erstrecken, ist die Firma „AG“ berechtigt, Zwischenabrechnungen bzw. Vorausrechnungen zu erstellen
              oder Akontozahlungen abzurufen.
              <br />
              <b> 8.2.</b>Das Honorar versteht sich als Netto-Honorar zuzüglich der Umsatzsteuer in gesetzlicher Höhe. Mangels Vereinbarung im Einzelfall hat die „AG“ für die erbrachten Leistungen und
              die Überlassung der urheber- und kennzeichenrechtlichen Nutzungsrechte Anspruch auf Honorar in der marktüblichen Höhe.
              <br />
              <b> 8.3. </b>Alle Leistungen der „AG“, die nicht ausdrücklich durch das vereinbarte Honorar abgegolten sind, werden gesondert entlohnt. Alle der „AG“ erwachsenden Barauslagen sind vom
              Kunden zu ersetzen.
              <br />
              <b> 8.4.</b>Kostenvoranschläge der „AG“ sind unverbindlich. Wenn abzusehen ist, dass die tatsächlichen Kosten, die von der „AG“ schriftlich veranschlagten, um mehr als 15 % übersteigen,
              wird die „AG“ den Kunden auf die höheren Kosten hinweisen. Die Kostenüberschreitung gilt als vom Kunden genehmigt, wenn der Kunde nicht binnen drei Werktagen nach diesem Hinweis
              schriftlich widerspricht und gleichzeitig kostengünstigere Alternativen bekannt gibt. Handelt es sich um eine Kostenüberschreitung bis 15 % ist eine gesonderte Verständigung nicht
              erforderlich. Diese Kostenvoranschlagsüberschreitung gilt vom Auftraggeber von vornherein als genehmigt.
              <br />
              <b>8.5. </b>Wenn der Kunde in Auftrag gegebene Arbeiten ohne Einbindung der „AG“ - unbeschadet der laufenden sonstigen Betreuung durch diese - einseitig ändert oder abbricht, hat er der
              „AG“ die bis dahin erbrachten Leistungen entsprechend der Honorarvereinbarung zu vergüten und alle angefallenen Kosten zu erstatten. Sofern der Abbruch nicht durch eine grob fahrlässige
              oder vorsätzliche Pflichtverletzung der „AG“ begründet ist, hat der Kunde der „AG“ darüber hinaus das gesamte für diesen Auftrag vereinbarte Honorar (Provision) zu erstatten, wobei die
              Anrechnungsvergütung des § 1168 AGBG ausgeschlossen wird. Weiters ist die „AG“ bezüglich allfälliger Ansprüche Dritter, insbesondere von Auftragnehmern der „AG“, schad- und klaglos zu
              stellen. Mit der Bezahlung des Entgelts erwirbt der Kunde an bereits erbrachten Arbeiten keinerlei Nutzungsrechte; nicht ausgeführte Konzepte, Entwürfe und sonstige Unterlagen sind
              vielmehr unverzüglich der „AG“ zurückzustellen.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
            <Typography>9. Zahlung, Eigentumsvorbehalt</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <br />
              <b>9.1.</b>Das Honorar ist sofort mit Rechnungserhalt und ohne Abzug zur Zahlung fällig, sofern nicht im Einzelfall besondere Zahlungsbedingungen schriftlich vereinbart werden. Dies gilt
              auch für die Weiterverrechnung sämtlicher Barauslagen und sonstiger Aufwendungen. Die von der „AG“ gelieferte Ware bleibt bis zur vollständigen Bezahlung des Entgelts einschließlich
              aller Nebenverbindlichkeiten im Eigentum der „AG“.
              <br />
              <b>9.2.</b>Bei Zahlungsverzug des Kunden gelten die gesetzlichen Verzugszinsen in der für Unternehmergeschäfte geltenden Höhe. Weiters verpflichtet sich der Kunde für den Fall des
              Zahlungsverzugs, der „AG“ die entstehenden Mahn- und Inkassospesen, soweit sie zur zweckentsprechenden Rechtsverfolgung notwendig sind, zu ersetzen. Dies umfasst jedenfalls die Kosten
              zweier Mahnschreiben in marktüblicher Höhe von derzeit zumindest € 20,00 je Mahnung sowie eines Mahnschreibens eines mit der Eintreibung beauftragten Rechtsanwalts. Die Geltendmachung
              weitergehender Rechte und Forderungen bleibt davon unberührt. <br />
              <b> 9.3.</b>Im Falle des Zahlungsverzuges des Kunden kann die „AG“ sämtliche, im Rahmen anderer mit dem Kunden abgeschlossener Verträge, erbrachten Leistungen und Teilleistungen sofort
              fällig stellen.
              <br />
              <b> 9.4.</b>Weiters ist die „AG“ nicht verpflichtet, weitere Leistungen bis zur Begleichung des aushaftenden Betrages zu erbringen (Zurückbehaltungsrecht). Die Verpflichtung zur
              Entgeltzahlung bleibt davon unberührt.
              <br />
              <b> 9.5.</b>Wurde die Bezahlung in Raten vereinbart, so behält sich die „AG“ für den Fall der nicht fristgerechten Zahlung von Teilbeträgen oder Nebenforderungen das Recht vor, die
              sofortige Bezahlung der gesamten noch offenen Schuld zu fordern (Terminverlust).
              <br />
              <b> 9.6.</b>Der Kunde ist nicht berechtigt, mit eigenen Forderungen gegen Forderungen der „AG“ aufzurechnen, außer die Forderung des Kunden wurde von der „AG“ schriftlich anerkannt oder
              gerichtlich festgestellt.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
            <Typography>10. Eigentumsrecht und Urheberrecht</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <br />
              <b>10.1.</b>Alle Leistungen der„AG“, einschließlich jener aus Präsentationen (z.B. Anregungen, Ideen, Skizzen, Vorentwürfe, Skribbles, Reinzeichnungen, Konzepte, Negative, Dias), auch
              einzelne Teile daraus, bleiben ebenso wie die einzelnen Werkstücke und Entwurfsoriginale im Eigentum der „AG“ und können von der „AG“ jederzeit - insbesondere bei Beendigung des
              Vertragsverhältnisses - zurückverlangt werden. Der Kunde erwirbt durch Zahlung des Honorars das Recht der Nutzung für den vereinbarten Verwendungszweck. Mangels anderslautender
              Vereinbarung darf der Kunde die Leistungen der „AG“ jedoch ausschließlich in Österreich nutzen. Der Erwerb von Nutzungs- und Verwertungsrechten an Leistungen der „AG“ setzt in jedem Fall
              die vollständige Bezahlung der von der „AG“ dafür in Rechnung gestellten Honorare voraus. Nutzt der Kunde bereits vor diesem Zeitpunkt die Leistungen der „AG“, so beruht diese Nutzung
              auf einem jederzeit widerrufbaren Leihverhältnis. <br />
              <b> 10.2.</b>Änderungen bzw. Bearbeitungen von Leistungen der„AG“, wie insbesondere deren Weiterentwicklung durch den Kunden oder durch für diesen tätige Dritte, sind nur mit
              ausdrücklicher Zustimmung der „AG“ und - soweit die Leistungen urheberrechtlich geschützt sind - des Urhebers zulässig.
              <br />
              <b> 10.3. </b>Für die Nutzung von Leistungen der „AG“, die über den ursprünglich vereinbarten Zweck und Nutzungsumfang hinausgeht, ist - unabhängig davon, ob diese Leistung
              urheberrechtlich geschützt ist - die Zustimmung der „AG“ erforderlich. Dafür steht der „AG“ und dem Urheber eine gesonderte angemessene Vergütung zu.
              <br />
              <b> 10.4. </b>Für die Nutzung von Leistungen der „AG“ bzw. von Werbemitteln, für die die „AG“ konzeptionelle oder gestalterische Vorlagen erarbeitet hat, ist nach Ablauf des Vertrags
              unabhängig davon, ob diese Leistung urheberrechtlich geschützt ist oder nicht, ebenfalls die Zustimmung der „AG“ notwendig.
              <br />
              <b> 10.5.</b>Für Nutzungen gemäß Abs 2. steht der „AG“ im 1. Jahr nach Vertragsende ein Anspruch auf die volle im abgelaufenen Vertrag vereinbarte Vergütung zu. Im 2. bzw. 3. Jahr nach
              Ablauf des Vertrages nur mehr die Hälfte bzw. ein Viertel der im Vertrag vereinbarten Vergütung. Ab dem 4. Jahr nach Vertragsende ist keine Vergütung mehr zu zahlen.
              <br />
              <b> 10.6. </b>Der Kunde haftet für jede widerrechtliche Nutzung in doppelter Höhe des für diese Nutzung angemessenen Honorars.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
            <Typography>11. Kennzeichnung</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <b>11.1. </b>Die „AG“ ist berechtigt, auf allen Werbemitteln und bei allen Werbemaßnahmen auf die „AG“ und allenfalls auf den Urheber hinzuweisen, ohne dass dem Kunden dafür ein
              Entgeltanspruch zusteht.
              <br />
              <b> 11.2. </b>Die „AG“ ist vorbehaltlich des jederzeit möglichen, schriftlichen Widerrufs des Kunden dazu berechtigt, auf eigenen Werbeträgern und insbesondere auf ihrer Internet-Website
              mit Namen und Firmenlogo auf die zum Kunden bestehende oder vormalige Geschäftsbeziehung hinzuweisen (Referenzhinweis).
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
            <Typography>12. Gewährleistung</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <br />
              <b>12.1.</b>Der Kunde hat allfällige Mängel unverzüglich, jedenfalls innerhalb von acht Tagen nach Lieferung/Leistung durch die „AG“, verdeckte Mängel innerhalb von acht Tagen nach
              Erkennen derselben, schriftlich unter Beschreibung des Mangels anzuzeigen; andernfalls gilt die Leistung als genehmigt. In diesem Fall ist die Geltendmachung von Gewährleistungs- und
              Schadenersatzansprüchen sowie das Recht auf Irrtumsanfechtung aufgrund von Mängeln ausgeschlossen.
              <br />
              <b>12.2. </b>Im Fall berechtigter und rechtzeitiger Mängelrüge steht dem Kunden das Recht auf Verbesserung oder Austausch der Lieferung/Leistung durch die „AG“ zu. Die „AG“ wird die
              Mängel in angemessener Frist beheben, wobei der Kunde der „AG“ alle zur Untersuchung und Mängelbehebung erforderlichen Maßnahmen ermöglicht. Die „AG“ ist berechtigt, die Verbesserung der
              Leistung zu verweigern, wenn diese unmöglich oder für die „AG“ mit einem unverhältnismäßig hohen Aufwand verbunden ist. In diesem Fall stehen dem Kunden die gesetzlichen Wandlungs- oder
              Minderungsrechte zu. Im Fall der Verbesserung obliegt es dem Auftraggeber die Übermittlung der mangelhaften (körperlichen) Sache auf seine Kosten durchzuführen.
              <br />
              <b> 12.3.</b>Es obliegt auch dem Auftraggeber, die Überprüfung der Leistung auf ihre rechtliche, insbesondere wettbewerbs-, marken-, urheber- und verwaltungsrechtliche Zulässigkeit
              durchzuführen. Die „AG“ ist nur zu einer Grobprüfung der rechtlichen Zulässigkeit verpflichtet. Die „AG“ haftet im Falle leichter Fahrlässigkeit oder nach Erfüllung einer allfälligen
              Warnpflicht gegenüber dem Kunden nicht für die rechtliche Zulässigkeit von Inhalten, wenn diese vom Kunden vorgegeben oder genehmigt wurden.
              <br />
              <b> 12.4.</b>Die Gewährleistungsfrist beträgt sechs Monate ab Lieferung/Leistung. Das Recht zum Regress gegenüber der „AG“ gemäß § 933b Abs 1 ABGB erlischt ein Jahr nach
              Lieferung/Leistung. Der Kunde ist nicht berechtigt, Zahlungen wegen Bemängelungen zurückzuhalten. Die Vermutungsregelung des § 924 ABGB wird ausgeschlossen.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
            <Typography>13. Haftung und Produkthaftung</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <b>13.1.</b>In Fällen leichter Fahrlässigkeit ist eine Haftung der „AG“ und die ihrer Angestellten, Auftragnehmer oder sonstigen Erfüllungsgehilfen („Freelancer“) für Sach- oder
              Vermögensschäden des Kunden ausgeschlossen, gleichgültig ob es sich um unmittelbare oder mittelbare Schäden, entgangenen Gewinn oder Mangelfolgeschäden, Schäden wegen Verzugs,
              Unmöglichkeit, positiver Forderungsverletzung, Verschuldens bei Vertragsabschluss, wegen mangelhafter oder unvollständiger Leistung handelt. Das Vorliegen von grober Fahrlässigkeit hat
              der Geschädigte zu beweisen. Soweit die Haftung der „AG“ ausgeschlossen oder beschränkt ist, gilt dies auch für die persönliche Haftung ihrer „Leute“.
              <br />
              <b>13.2.</b>Jegliche Haftung der „AG“ für Ansprüche, die auf Grund der von der erbrachten Leistung (z.B. Werbemaßnahme) gegen den Kunden erhoben werden, wird ausdrücklich
              ausgeschlossen, wenn die „AG“ ihrer Hinweispflicht nachgekommen ist oder eine solche für sie nicht erkennbar war, wobei leichte Fahrlässigkeit nicht schadet. Insbesondere haftet die „AG“
              nicht für Prozesskosten, eigene Anwaltskosten des Kunden oder Kosten von Urteilsveröffentlichungen sowie für allfällige Schadenersatzforderungen oder sonstige Ansprüche Dritter; der
              Kunde hat die „AG“ diesbezüglich schad- und klaglos zu halten.
              <br />
              <b> 13.3.</b>Schadensersatzansprüche des Kunden verfallen in sechs Monaten ab Kenntnis des Schadens; jedenfalls aber nach drei Jahren ab der Verletzungshandlung der „AG“.
              Schadenersatzansprüche sind der Höhe nach mit dem Netto-Auftragswert begrenzt.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
            <Typography>14. Datenschutz</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Der Kunde stimmt zu, dass seine persönlichen Daten, nämlich Name/Firma, Beruf, Geburtsdatum, Firmenbuchnummer, Vertretungsbefugnisse, Ansprechperson, Geschäftsanschrift und sonstige
              Adressen des Kunden, Telefonnummer, Telefaxnummer, E-Mail-Adresse, Bankverbindungen, Kreditkartendaten, UID-Nummer) zum Zwecke der Vertragserfüllung und Betreuung des Kunden sowie für
              eigene Werbezwecke, beispielsweise zur Zusendung von Angeboten, Werbeprospekten und Newsletter (in Papier- und elektronischer Form), sowie zum Zwecke des Hinweises auf die zum Kunden
              bestehende oder vormalige Geschäftsbeziehung (Referenzhinweis) automationsunterstützt ermittelt, gespeichert und verarbeitet werden. Der Auftraggeber ist einverstanden, dass ihm
              elektronische Post zu Werbezwecken bis auf Widerruf zugesendet wird. Diese Zustimmung kann jederzeit schriftlich mittels E-Mail, Telefax oder Brief an die im Kopf der AGB angeführten
              Kontaktdaten widerrufen werden.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
            <Typography>15. Anzuwendendes Recht</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Der Vertrag und alle daraus abgeleiteten wechselseitigen Rechte und Pflichten sowie Ansprüche zwischen der „AG“ und dem Kunden unterliegen dem österreichischen materiellen Recht unter
              Ausschluss seiner Verweisungsnormen und unter Ausschluss des UN-Kaufrechts.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
            <Typography>16. Erfüllungsort und Gerichtsstand</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <b>16.1.</b>Erfüllungsort ist der Sitz der „AG“. Bei Versand geht die Gefahr auf den Kunden über, sobald die „AG“ die Ware dem von ihr gewählten Beförderungsunternehmen übergeben hat.
              <br />
              <b> 16.2.</b>Als Gerichtsstand für alle sich zwischen der „AG“ und dem Kunden ergebenden Rechtsstreitigkeiten im Zusammenhang mit diesem Vertragsverhältnis wird, das für den Sitz der
              „AG“ sachlich zuständige Gericht vereinbart. Ungeachtet dessen ist die „AG“ berechtigt, den Kunden an seinem allgemeinen Gerichtsstand zu klagen.
              <br />
              <b> 16.3.</b>Soweit in diesem Vertrag auf natürliche Personen bezogene Bezeichnungen nur in männlicher Form angeführt sind, beziehen sie sich auf Frauen und Männer in gleicher Weise. Bei
              der Anwendung der Bezeichnung auf bestimmte natürliche Personen ist die jeweils geschlechtsspezifische Form zu verwenden.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}

import ClientProfit from "./components/smartBoard/clientProfit";
import Hero from "./components/smartBoard/hero";
import Information from "./components/smartBoard/informations";
import WhyUs from "./components/smartBoard/whyUs";

export default function SmartBoard() {
  return (
    <>
      <Hero />
      <ClientProfit />
      {/* <Features /> */}
      <Information />
      <WhyUs />
      {/* <Individual /> */}
    </>
  );
}
